/* tslint:disable:no-console */
import { logError, logErrorText } from "@/services/api/serverLogger";
import { CertFullInfo } from "@/@types/certificateInfo";
import { isEmpty } from "lodash";

import CertificateAdjuster from "./CertificateAdjuster";
import Notifications from "@/lib/notifications";
import { APPLICANT_TYPES } from "@/store/modules/application/application.constants";
import { CertificateCheckInfo } from "@/@types/certificateCheckInfo";

const notify = new Notifications({
  type: "error",
  duration: 5000,
  position: ["left", "top"],
  animationOptions: {
    animateIn: "fadeInLeft",
    animateOut: "flipOutX"
  },
  styles: {
    textAlign: "left",
    padding: [15, 25],
    width: 450
  },
  className: {
    main: "notify-all"
  }
});

const IS_DEV = process.env.NODE_ENV === "development";
const IS_STAGING = process.env.NODE_ENV === "staging";
const ISSUER_STOP_LIST = [
  'CN="АО ""КАЛУГА АСТРАЛ"""',
  'CN=AC NEP CA GOST',
];

export default class CryptoProWrapper {
  public certs: Array<{
    label: string | string[];
    value: string;
    cert: any;
    fullInfo: CertFullInfo;
    alg: ValuesOf<CAdESCOM.CADESCOM_HASH_ALGORITHM>;
  }> = [];
  public browserPluginEnabled: boolean = false;
  private isLoadingCerts: boolean = false;
  private versionCheckResult: {
    version: string;
    error: string;
    hasCryptoProvider: boolean;
  } = {
    version: "",
    error: "",
    hasCryptoProvider: false
  };
  get IsLoadingCerts(): boolean {
    return this.isLoadingCerts;
  }

  constructor() {
    /**/
  }

  public init(): void {
    const canPromise = !!(window as any).Promise;

    console.debug("Can async ", this.canAsync(cadesplugin));

    cadesplugin.then(
      () => {
        this.main();
      },
      error => {
        const msg = "Невозможно загрузить КриптоПро ЭЦП Browser plug-in (init)";
        logErrorText(msg);
        console.error(msg);
        // notify.show({
        //   title: "Невозможно загрузить КриптоПро ЭЦП Browser plug-in"
        // });
      }
    );

    // if (this.canAsync(cadesplugin)) {

    // } else {
    //   alert("sync");
    //   try {
    //     window.addEventListener(
    //       "message",
    //       event => {
    //         if (event.data === "cadesplugin_loaded") {
    //           this.main();
    //         } else if (event.data === "cadesplugin_load_error") {
    //           console.error(
    //             `Невозможно загрузить КриптоПро ЭЦП Browser plug-in`
    //           );
    //         }
    //       },
    //       false
    //     );
    //     window.postMessage("cadesplugin_echo_request", "*");
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
  }

  public load(): void {
    cadesplugin.then(
      () => {
        this.browserPluginEnabled = true;
      },
      e => {
        const msg = "Невозможно загрузить КриптоПро ЭЦП Browser plug-in (load)";
        logErrorText(msg);
        console.error(msg);
        // notify.show({
        //   title: "Невозможно загрузить КриптоПро ЭЦП Browser plug-in"
        // });
      }
    );
  }

  public async sign(
    certThumbprint: string,
    dataAsBase64: string
  ): Promise<string | null> {
    if (this.canAsync(cadesplugin)) {
      return await this.SignCreateAsync(
        cadesplugin,
        certThumbprint,
        dataAsBase64
      );
    } else if (this.canSync(cadesplugin)) {
      return this.SignCreate(cadesplugin, certThumbprint, dataAsBase64);
    } else {
      return null;
    }
  }

  public async signHash(hash: string, certThumbprint: string): Promise<string> {
    if (this.canAsync(cadesplugin)) {
      return (await this.SignHash(cadesplugin, certThumbprint, hash)) || "";
    } else if (this.canSync(cadesplugin)) {
      return this.SignHashSync(cadesplugin, certThumbprint, hash) || "";
    } else {
      return "";
    }
  }

  public async calcHash(dataBase64Str: string, alg: ValuesOf<CAdESCOM.CADESCOM_HASH_ALGORITHM>): Promise<string> {
    if(this.canAsync(cadesplugin)) {
      return (await this.CalcHashAsync(cadesplugin, alg, dataBase64Str)) || "";
    } else if (this.canSync(cadesplugin)) {
      return this.CalcHashSync(cadesplugin, alg, dataBase64Str) || "";
    } else {
      return "";
    }
  }

  public async createCertRequest(
    thumbprint: string,
    oids: string[],
    email: string,
    identificationKind: number,
    exportPolicy: number,
  ): Promise<string> {
    if (this.canAsync(cadesplugin)) {
      return await this.createCertReqAsync(
        cadesplugin,
        thumbprint,
        oids,
        email,
        identificationKind,
        exportPolicy
      );
    } else if (this.canSync(cadesplugin)) {
      return this.createCertReq(cadesplugin, thumbprint, oids, email,identificationKind,exportPolicy);
    } else {
      return "";
    }
  }

  public async createNewCertRequest(
    subject: string,
    oids: string[],
    identificationKind: number,
    exportPolicy: number,
  ): Promise<string> {
    if (this.canAsync(cadesplugin)) {
      return await this.createNewCertReqAsync(cadesplugin, subject, oids, identificationKind,exportPolicy);
    } else if (this.canSync(cadesplugin)) {
      return this.createNewCertReq(cadesplugin, subject, oids, identificationKind,exportPolicy);
    } else {
      return "";
    }
  }

  public async getCertificates({filter}:{filter:string}={filter:""},appData: CertificateCheckInfo): Promise<void> {
    if(this.IsLoadingCerts)
      return;
    this.certs = [];

    await this.checkCSP()
      .then(async () => {
        if (!this.versionCheckResult.hasCryptoProvider) {
          return;
        }
        if (this.canAsync(cadesplugin)) {
          await this.getCertificatesList(cadesplugin as CADESPluginAsync, filter,appData);
        } else if (this.canSync(cadesplugin)) {
          this.getCertificatesListSync(cadesplugin as CADESPluginSync, filter,appData);
        } else {
          return;
        }
      })
      .catch(reason => {
        notify.show({
          title: "Ошибка при проверке версии криптопровайдера"
        });
        throw new Error("Ошибка при проверке версии криптопровайдера");
      })
      .finally(() => {
        if (!isEmpty(this.versionCheckResult.error)) {
          throw this.versionCheckResult.error;
        }
      });
  }

  public async exportCertificate(thumbprint: string): Promise<string> {
    if (this.canAsync(cadesplugin)) {
      return await this.exportCertAsync(cadesplugin, thumbprint);
    } else if (this.canSync(cadesplugin)) {
      return this.exportCert(cadesplugin, thumbprint);
    } else {
      return "";
    }
  }

  private exportCert(cadesplugin: CADESPluginSync, thumbprint: string) {
    const store = cadesplugin.CreateObject("CAdESCOM.Store");

    store.Open(
      cadesplugin.CAPICOM_CURRENT_USER_STORE,
      cadesplugin.CAPICOM_MY_STORE,
      cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
    );

    const certificates = store.Certificates.Find(
      cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
      thumbprint
    );
    if (certificates.Count === 0) {
      const msg = `Сертификат ${thumbprint} не найден (#1)`;
      logErrorText(msg);

      notify.show({
        title: msg
      });
      return "";
    }

    const certificate = certificates.Item(1);

    return certificate.Export(0);
  }

  private async exportCertAsync(
    cadesplugin: CADESPluginAsync,
    thumbprint: string
  ): Promise<string> {
    const store = await cadesplugin.CreateObjectAsync("CAdESCOM.Store");

    await store.Open(
      cadesplugin.CAPICOM_CURRENT_USER_STORE,
      cadesplugin.CAPICOM_MY_STORE,
      cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
    );

    let storeCerts = await store.Certificates;
    let certificates = await storeCerts.Find(
      cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
      thumbprint
    );
    if ((await certificates.Count) === 0)
    {
      await store.Close();
      await store.Open(
        cadesplugin.CADESCOM_CONTAINER_STORE
      );

      storeCerts = await store.Certificates;
      certificates = await storeCerts.Find(
        cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
        thumbprint
      );

      if ((await certificates.Count) === 0) {
        const msg = `Сертификат ${thumbprint} не найден (#2)`;
        logErrorText(msg);

        notify.show({
          title: msg
        });
        return "";
      }
    }
    const cert = await certificates.Item(1);

    return await cert.Export(0);
  }

  private async checkCSP(): Promise<void> {
    if (this.canAsync(cadesplugin)) {
      await this.checkVersionAsync(cadesplugin);
    } else if (this.canSync(cadesplugin)) {
      this.checkVersion(cadesplugin);
    } else {
      return;
    }
  }

  private checkVersion(cadesplugin: CADESPluginSync): void {
    const ProviderName =
      "Crypto-Pro GOST R 34.10-2012 Cryptographic Service Provider";
    const ProviderType = 80;

    let oVersion;
    try {
      const oAbout = cadesplugin.CreateObject("CAdESCOM.About");

      oVersion = oAbout.CSPVersion(ProviderName, ProviderType);
      this.versionCheckResult = {
        version: oVersion.toString(),
        error: "",
        hasCryptoProvider: true
      };
    } catch (er) {
      if (er.message.indexOf("0x80090019") + 1) {
        this.versionCheckResult = {
          version: "",
          error: `На Вашем компьютере не обнаружен необходимый криптопровайдер: ${ProviderName}`,
          hasCryptoProvider: false
        };
      } else {
        // TODO Log er.message
        this.versionCheckResult = {
          version: "",
          error:
            "Ошибка при проверке наличия криптопровайдера." +
            " Попробуйте ещё раз или обратитесь в поддержку",
          hasCryptoProvider: false
        };
      }
      logError(er, this.versionCheckResult.error);
    }
  }

  private async checkVersionAsync(
    cadesplugin: CADESPluginAsync
  ): Promise<void> {
    const ProviderName =
      "Crypto-Pro GOST R 34.10-2012 Cryptographic Service Provider";
    const ProviderType = 80;

    let oVersion;
    try {
      const oAbout = await cadesplugin.CreateObjectAsync("CAdESCOM.About");
      oVersion = await oAbout.CSPVersion(ProviderName, ProviderType);
      this.versionCheckResult = {
        version: await oVersion.toString(),
        error: "",
        hasCryptoProvider: true
      };
    } catch (er) {
      if (er.message.indexOf("0x80090019") + 1) {
        this.versionCheckResult = {
          version: "",
          error: `На Вашем компьютере не обнаружен необходимый криптопровайдер: ${ProviderName}`,
          hasCryptoProvider: false
        };
      } else {
        // TODO Log er.message
        console.debug(er.message);
        this.versionCheckResult = {
          version: "",
          error:
            "Ошибка при проверке наличия криптопровайдера." +
            " Попробуйте ещё раз или обратитесь в поддержку",
          hasCryptoProvider: false
        };
      }
      logError(er, this.versionCheckResult.error);
    }
  }

  private async createCertReqAsync(
    cadesplugin: CADESPluginAsync,
    thumbprint: string,
    oids: string[],
    email: string,
    identificationKind: number,
    exportPolicy: number,
  ): Promise<string> {
    const store = await cadesplugin.CreateObjectAsync("CAdESCOM.Store");

    await store.Open(
      cadesplugin.CAPICOM_CURRENT_USER_STORE,
      cadesplugin.CAPICOM_MY_STORE,
      cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
    );

    let storeCerts = await store.Certificates;
    let certificates = await storeCerts.Find(
      cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
      thumbprint
    );
    if ((await certificates.Count) === 0) {

      await store.Close();

      await store.Open(
        cadesplugin.CADESCOM_CONTAINER_STORE
      );

      storeCerts = await store.Certificates;
      certificates = await storeCerts.Find(
        cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
        thumbprint
      );
      if ((await certificates.Count) === 0) {

        notify.show({
          title: `Сертификат ${thumbprint} не найден (#3)`
        });
        return "";
      }
    }
    const cert = await certificates.Item(1);

    const PKey = await cadesplugin.CreateObjectAsync(
      "X509Enrollment.CX509PrivateKey"
    );
    PKey.propset_ProviderName(
      "Crypto-Pro GOST R 34.10-2012 Cryptographic Service Provider"
    );
    PKey.propset_ProviderType(80);
    PKey.propset_ExportPolicy(exportPolicy); // X509PrivateKeyExportFlags: XCN_NCRYPT_ALLOW_EXPORT_NONE = 0, XCN_NCRYPT_ALLOW_EXPORT_FLAG = 0x1, etc
    PKey.propset_KeySpec(1); // XCN_AT_KEYEXCHANGE = может использоваться для шифрования и для цифровой подписи

    const CertificateRequestPkcs10 = await cadesplugin.CreateObjectAsync(
      "X509Enrollment.CX509CertificateRequestPkcs10"
    );
    CertificateRequestPkcs10.InitializeFromPrivateKey(1, PKey, "");

    const DistinguishedName = await cadesplugin.CreateObjectAsync(
      "X509Enrollment.CX500DistinguishedName"
    );

    const adjuster = new CertificateAdjuster();
    DistinguishedName.Encode(
      adjuster.replaceEmail(await cert.SubjectName, email),
      2097152
    );
    await CertificateRequestPkcs10.propset_Subject(DistinguishedName);

    const X509Extensions = await CertificateRequestPkcs10.X509Extensions;

    const KeyUsageExtension = await cadesplugin.CreateObjectAsync(
      "X509Enrollment.CX509ExtensionKeyUsage"
    );
    const CERT_DATA_ENCIPHERMENT_KEY_USAGE = 0x10;
    const CERT_KEY_ENCIPHERMENT_KEY_USAGE = 0x20;
    const CERT_DIGITAL_SIGNATURE_KEY_USAGE = 0x80;
    const CERT_NON_REPUDIATION_KEY_USAGE = 0x40;

    KeyUsageExtension.InitializeEncode(
      // tslint:disable-next-line:no-bitwise
      CERT_KEY_ENCIPHERMENT_KEY_USAGE |
        CERT_DATA_ENCIPHERMENT_KEY_USAGE |
        CERT_DIGITAL_SIGNATURE_KEY_USAGE |
        CERT_NON_REPUDIATION_KEY_USAGE
    );

    X509Extensions.Add(KeyUsageExtension);

    const EnhancedKeyUsage = await cadesplugin.CreateObjectAsync(
      "X509Enrollment.CX509ExtensionEnhancedKeyUsage"
    );
    const objObjectIds = await cadesplugin.CreateObjectAsync(
      "X509Enrollment.CObjectIds"
    );

    try {
      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < oids.length; index++) {
        const oid = oids[index];
        const objObjectId = await cadesplugin.CreateObjectAsync(
          "X509Enrollment.CObjectId"
        );
        objObjectId.InitializeFromValue(oid);
        objObjectIds.Add(objObjectId);
      }
    } catch (error) {
      throw error;
    }

    EnhancedKeyUsage.InitializeEncode(objObjectIds);
    X509Extensions.Add(EnhancedKeyUsage);

    ///// identificationKind OID 1.2.643.100.114 field generation
    const advancedExt = await cadesplugin.CreateObjectAsync(
      "X509Enrollment.CX509Extension"
    );

    const identificationKindObjectId = await cadesplugin.CreateObjectAsync(
      "X509Enrollment.CObjectId"
    );
    identificationKindObjectId.InitializeFromValue("1.2.643.100.114");

    advancedExt.Initialize(identificationKindObjectId, 4, "02010" + identificationKind.toFixed());

    X509Extensions.Add(advancedExt);
    /////

    ///// Атрибут 1.3.6.1.4.1.311.13.2.1 (Пара имени и значение заявки)
    const nameValuePairs = await CertificateRequestPkcs10.NameValuePairs;

    const pairs = {
      "PrivateKeyValidityPeriod" : "Months",
      "PrivateKeyValidityPeriodUnits" : "15",
      "ValidityPeriod" : "Months",
      "ValidityPeriodUnits" : "12",
    };

    Object.entries(pairs).forEach(async ([name, value]) => {
      const pairObject = await cadesplugin.CreateObjectAsync(
        "X509Enrollment.CX509NameValuePair"
      );
      pairObject.Initialize(name, value);
      nameValuePairs.Add(pairObject);
    })
    /////

    const Enroll = await cadesplugin.CreateObjectAsync(
      "X509Enrollment.CX509Enrollment"
    );
    Enroll.InitializeFromRequest(CertificateRequestPkcs10);

    const CRYPT_STRING_BASE64REQUESTHEADER = 3;
    return await Enroll.CreateRequest(CRYPT_STRING_BASE64REQUESTHEADER);
  }

  private async createNewCertReqAsync(
    cadesplugin: CADESPluginAsync,
    subject: string,
    oids: string[],
    identificationKind: number,
    exportPolicy: number,
  ): Promise<string> {
    const PKey = await cadesplugin.CreateObjectAsync(
      "X509Enrollment.CX509PrivateKey"
    );
    PKey.propset_ProviderName(
      "Crypto-Pro GOST R 34.10-2012 Cryptographic Service Provider"
    );
    PKey.propset_ProviderType(80);
    PKey.propset_ExportPolicy(exportPolicy); // X509PrivateKeyExportFlags: XCN_NCRYPT_ALLOW_EXPORT_NONE = 0, XCN_NCRYPT_ALLOW_EXPORT_FLAG = 0x1, etc
    PKey.propset_KeySpec(1); // XCN_AT_KEYEXCHANGE = может использоваться для шифрования и для цифровой подписи

    const CertificateRequestPkcs10 = await cadesplugin.CreateObjectAsync(
      "X509Enrollment.CX509CertificateRequestPkcs10"
    );
    CertificateRequestPkcs10.InitializeFromPrivateKey(1, PKey, "");

    const DistinguishedName = await cadesplugin.CreateObjectAsync(
      "X509Enrollment.CX500DistinguishedName"
    );

    // const adjuster = new CertificateAdjuster();
    // DistinguishedName.Encode(
    //   adjuster.replaceEmail(await cert.SubjectName, email),
    //   2097152
    // );
    // await CertificateRequestPkcs10.propset_Subject(DistinguishedName);
    DistinguishedName.Encode(subject);
    await CertificateRequestPkcs10.propset_Subject(DistinguishedName);

    const X509Extensions = await CertificateRequestPkcs10.X509Extensions;

    const KeyUsageExtension = await cadesplugin.CreateObjectAsync(
      "X509Enrollment.CX509ExtensionKeyUsage"
    );
    const CERT_DATA_ENCIPHERMENT_KEY_USAGE = 0x10;
    const CERT_KEY_ENCIPHERMENT_KEY_USAGE = 0x20;
    const CERT_DIGITAL_SIGNATURE_KEY_USAGE = 0x80;
    const CERT_NON_REPUDIATION_KEY_USAGE = 0x40;

    KeyUsageExtension.InitializeEncode(
      // tslint:disable-next-line:no-bitwise
      CERT_KEY_ENCIPHERMENT_KEY_USAGE |
        CERT_DATA_ENCIPHERMENT_KEY_USAGE |
        CERT_DIGITAL_SIGNATURE_KEY_USAGE |
        CERT_NON_REPUDIATION_KEY_USAGE
    );

    X509Extensions.Add(KeyUsageExtension);

    const EnhancedKeyUsage = await cadesplugin.CreateObjectAsync(
      "X509Enrollment.CX509ExtensionEnhancedKeyUsage"
    );
    const objObjectIds = await cadesplugin.CreateObjectAsync(
      "X509Enrollment.CObjectIds"
    );

    try {
      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < oids.length; index++) {
        const oid = oids[index];
        const objObjectId = await cadesplugin.CreateObjectAsync(
          "X509Enrollment.CObjectId"
        );
        objObjectId.InitializeFromValue(oid);
        objObjectIds.Add(objObjectId);
      }
    } catch (error) {
      logError(error, "Ошибка при добавлении oids");
      throw error;
    }

    EnhancedKeyUsage.InitializeEncode(objObjectIds);
    X509Extensions.Add(EnhancedKeyUsage);

    ///// identificationKind OID 1.2.643.100.114 field generation
    const advancedExt = await cadesplugin.CreateObjectAsync(
      "X509Enrollment.CX509Extension"
    );

    const identificationKindObjectId = await cadesplugin.CreateObjectAsync(
      "X509Enrollment.CObjectId"
    );
    identificationKindObjectId.InitializeFromValue("1.2.643.100.114");

    advancedExt.Initialize(identificationKindObjectId, 4, "02010" + identificationKind.toFixed());

    X509Extensions.Add(advancedExt);
    /////

    ///// Атрибут 1.3.6.1.4.1.311.13.2.1 (Пара имени и значение заявки)
    const nameValuePairs = await CertificateRequestPkcs10.NameValuePairs;

    const pairs = {
      "PrivateKeyValidityPeriod" : "Months",
      "PrivateKeyValidityPeriodUnits" : "15",
      "ValidityPeriod" : "Months",
      "ValidityPeriodUnits" : "12",
    };

    Object.entries(pairs).forEach(async ([name, value]) => {
      const pairObject = await cadesplugin.CreateObjectAsync(
        "X509Enrollment.CX509NameValuePair"
      );
      pairObject.Initialize(name, value);
      nameValuePairs.Add(pairObject);
    })
    /////

    const Enroll = await cadesplugin.CreateObjectAsync(
      "X509Enrollment.CX509Enrollment"
    );
    Enroll.InitializeFromRequest(CertificateRequestPkcs10);

    const CRYPT_STRING_BASE64REQUESTHEADER = 3;
    return await Enroll.CreateRequest(CRYPT_STRING_BASE64REQUESTHEADER);
  }

  private createCertReq(
    cadesplugin: CADESPluginSync,
    thumbprint: string,
    oids: string[],
    email: string,
    identificationKind: number,
    exportPolicy: number,
  ): string {
    const store = cadesplugin.CreateObject("CAdESCOM.Store");
    store.Open(
      cadesplugin.CAPICOM_CURRENT_USER_STORE,
      cadesplugin.CAPICOM_MY_STORE,
      cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
    );

    const certificates = store.Certificates.Find(
      cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
      thumbprint
    );
    if (certificates.Count === 0) {
      notify.show({
        title: `Сертификат ${thumbprint} не найден (#4)`
      });
      return "";
    }

    const certificate = certificates.Item(1);

    let PKey: any;
    try {
      PKey = cadesplugin.CreateObject("X509Enrollment.CX509PrivateKey");
    } catch (error) {
      console.debug(error);
    }

    PKey.ProviderName =
      "Crypto-Pro GOST R 34.10-2012 Cryptographic Service Provider";

    PKey.ProviderType = 80;
    PKey.ExportPolicy = exportPolicy; // X509PrivateKeyExportFlags: XCN_NCRYPT_ALLOW_EXPORT_NONE = 0, XCN_NCRYPT_ALLOW_EXPORT_FLAG = 0x1, etc
    PKey.KeySpec = 1; // XCN_AT_KEYEXCHANGE = может использоваться для шифрования и для цифровой подписи

    const CertificateRequestPkcs10 = cadesplugin.CreateObject(
      "X509Enrollment.CX509CertificateRequestPkcs10"
    );
    CertificateRequestPkcs10.InitializeFromPrivateKey(0x1, PKey, "");
    const DistinguishedName = cadesplugin.CreateObject(
      "X509Enrollment.CX500DistinguishedName"
    );

    const adjuster = new CertificateAdjuster();
    DistinguishedName.Encode(
      adjuster.replaceEmail(certificate.SubjectName, email)
    );
    CertificateRequestPkcs10.Subject = DistinguishedName;

    const KeyUsageExtension = cadesplugin.CreateObject(
      "X509Enrollment.CX509ExtensionKeyUsage"
    );
    const CERT_DATA_ENCIPHERMENT_KEY_USAGE = 0x10;
    const CERT_KEY_ENCIPHERMENT_KEY_USAGE = 0x20;
    const CERT_DIGITAL_SIGNATURE_KEY_USAGE = 0x80;
    const CERT_NON_REPUDIATION_KEY_USAGE = 0x40;

    KeyUsageExtension.InitializeEncode(
      // tslint:disable-next-line:no-bitwise
      CERT_KEY_ENCIPHERMENT_KEY_USAGE |
        CERT_DATA_ENCIPHERMENT_KEY_USAGE |
        CERT_DIGITAL_SIGNATURE_KEY_USAGE |
        CERT_NON_REPUDIATION_KEY_USAGE
    );
    CertificateRequestPkcs10.X509Extensions.Add(KeyUsageExtension);

    const EnhancedKeyUsage = cadesplugin.CreateObject(
      "X509Enrollment.CX509ExtensionEnhancedKeyUsage"
    );
    const objObjectIds = cadesplugin.CreateObject("X509Enrollment.CObjectIds");
    try {
      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < oids.length; index++) {
        const oid = oids[index];
        const objObjectId = cadesplugin.CreateObject(
          "X509Enrollment.CObjectId"
        );
        objObjectId.InitializeFromValue(oid);
        objObjectIds.Add(objObjectId);
      }
    } catch (error) {
      throw error;
    }

    EnhancedKeyUsage.InitializeEncode(objObjectIds);
    CertificateRequestPkcs10.X509Extensions.Add(EnhancedKeyUsage);

    ///// identificationKind OID 1.2.643.100.114 field generation
    const advancedExt = cadesplugin.CreateObject(
      "X509Enrollment.CX509Extension"
    );

    const identificationKindObjectId = cadesplugin.CreateObject(
      "X509Enrollment.CObjectId"
    );
    identificationKindObjectId.InitializeFromValue("1.2.643.100.114");

    // https://docs.microsoft.com/en-us/windows/win32/api/certenroll/ne-certenroll-encodingtype
    const XCN_CRYPT_STRING_HEX = 4;
    advancedExt.Initialize(identificationKindObjectId, XCN_CRYPT_STRING_HEX, "02010" + identificationKind.toFixed());

    CertificateRequestPkcs10.X509Extensions.Add(advancedExt);
    /////

    ///// Атрибут 1.3.6.1.4.1.311.13.2.1 (Пара имени и значение заявки)
    const nameValuePairs = CertificateRequestPkcs10.NameValuePairs;

    const pairs = {
      "PrivateKeyValidityPeriod" : "Months",
      "PrivateKeyValidityPeriodUnits" : "15",
      "ValidityPeriod" : "Months",
      "ValidityPeriodUnits" : "12",
    };

    Object.entries(pairs).forEach(([name, value]) => {
      const pairObject = cadesplugin.CreateObject(
        "X509Enrollment.CX509NameValuePair"
      );
      pairObject.Initialize(name, value);
      nameValuePairs.Add(pairObject);
    })
    /////

    const Enroll = cadesplugin.CreateObject("X509Enrollment.CX509Enrollment");
    Enroll.InitializeFromRequest(CertificateRequestPkcs10);

    const CRYPT_STRING_BASE64REQUESTHEADER = 3;

    return Enroll.CreateRequest(CRYPT_STRING_BASE64REQUESTHEADER);
  }

  private createNewCertReq(
    cadesplugin: CADESPluginSync,
    subject: string,
    oids: string[],
    identificationKind: number,
    exportPolicy: number,
  ): string {
    let PKey: any;
    try {
      PKey = cadesplugin.CreateObject("X509Enrollment.CX509PrivateKey");
    } catch (error) {
      console.debug(error);
    }

    PKey.ProviderName =
      "Crypto-Pro GOST R 34.10-2012 Cryptographic Service Provider";

    PKey.ProviderType = 80;
    PKey.ExportPolicy = exportPolicy; // X509PrivateKeyExportFlags: XCN_NCRYPT_ALLOW_EXPORT_NONE = 0, XCN_NCRYPT_ALLOW_EXPORT_FLAG = 0x1, etc
    PKey.KeySpec = 1; // XCN_AT_KEYEXCHANGE = может использоваться для шифрования и для цифровой подписи

    const CertificateRequestPkcs10 = cadesplugin.CreateObject(
      "X509Enrollment.CX509CertificateRequestPkcs10"
    );
    CertificateRequestPkcs10.InitializeFromPrivateKey(0x1, PKey, "");

    const DistinguishedName = cadesplugin.CreateObject(
      "X509Enrollment.CX500DistinguishedName"
    );

    // const adjuster = new CertificateAdjuster();
    // DistinguishedName.Encode(
    //   adjuster.replaceEmail(certificate.SubjectName, email)
    // );
    // CertificateRequestPkcs10.Subject = DistinguishedName;
    DistinguishedName.Encode(subject);
    CertificateRequestPkcs10.Subject = DistinguishedName;

    const KeyUsageExtension = cadesplugin.CreateObject(
      "X509Enrollment.CX509ExtensionKeyUsage"
    );
    const CERT_DATA_ENCIPHERMENT_KEY_USAGE = 0x10;
    const CERT_KEY_ENCIPHERMENT_KEY_USAGE = 0x20;
    const CERT_DIGITAL_SIGNATURE_KEY_USAGE = 0x80;
    const CERT_NON_REPUDIATION_KEY_USAGE = 0x40;

    KeyUsageExtension.InitializeEncode(
      // tslint:disable-next-line:no-bitwise
      CERT_KEY_ENCIPHERMENT_KEY_USAGE |
        CERT_DATA_ENCIPHERMENT_KEY_USAGE |
        CERT_DIGITAL_SIGNATURE_KEY_USAGE |
        CERT_NON_REPUDIATION_KEY_USAGE
    );
    CertificateRequestPkcs10.X509Extensions.Add(KeyUsageExtension);

    const EnhancedKeyUsage = cadesplugin.CreateObject(
      "X509Enrollment.CX509ExtensionEnhancedKeyUsage"
    );
    const objObjectIds = cadesplugin.CreateObject("X509Enrollment.CObjectIds");
    try {
      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < oids.length; index++) {
        const oid = oids[index];
        const objObjectId = cadesplugin.CreateObject(
          "X509Enrollment.CObjectId"
        );
        objObjectId.InitializeFromValue(oid);
        objObjectIds.Add(objObjectId);
      }
    } catch (error) {
      logError(error, "Ошибка добавления oids в запрос на сертификат");
      throw error;
    }

    EnhancedKeyUsage.InitializeEncode(objObjectIds);
    CertificateRequestPkcs10.X509Extensions.Add(EnhancedKeyUsage);

    ///// identificationKind OID 1.2.643.100.114 field generation
    const advancedExt = cadesplugin.CreateObject(
      "X509Enrollment.CX509Extension"
    );

    const identificationKindObjectId = cadesplugin.CreateObject(
      "X509Enrollment.CObjectId"
    );
    identificationKindObjectId.InitializeFromValue("1.2.643.100.114");

    // https://docs.microsoft.com/en-us/windows/win32/api/certenroll/ne-certenroll-encodingtype
    const XCN_CRYPT_STRING_HEX = 4;
    advancedExt.Initialize(identificationKindObjectId, XCN_CRYPT_STRING_HEX, "02010" + identificationKind.toFixed());

    CertificateRequestPkcs10.X509Extensions.Add(advancedExt);
    /////

    ///// Атрибут 1.3.6.1.4.1.311.13.2.1 (Пара имени и значение заявки)
    const nameValuePairs = CertificateRequestPkcs10.NameValuePairs;

    const pairs = {
      "PrivateKeyValidityPeriod" : "Months",
      "PrivateKeyValidityPeriodUnits" : "15",
      "ValidityPeriod" : "Months",
      "ValidityPeriodUnits" : "12",
    };

    Object.entries(pairs).forEach(([name, value]) => {
      const pairObject = cadesplugin.CreateObject(
        "X509Enrollment.CX509NameValuePair"
      );
      pairObject.Initialize(name, value);
      nameValuePairs.Add(pairObject);
    })
    /////

    const Enroll = cadesplugin.CreateObject("X509Enrollment.CX509Enrollment");
    Enroll.InitializeFromRequest(CertificateRequestPkcs10);

    const CRYPT_STRING_BASE64REQUESTHEADER = 3;

    return Enroll.CreateRequest(CRYPT_STRING_BASE64REQUESTHEADER);
  }

  private canAsync(cadesplugin: CADESPlugin): cadesplugin is CADESPluginAsync {
    return !!(cadesplugin as CADESPluginAsync).CreateObjectAsync;
  }

  private canSync(cadesplugin: CADESPlugin): cadesplugin is CADESPluginSync {
    return !!(cadesplugin as CADESPluginSync).CreateObject;
  }

  private async main(): Promise<void> {
    this.checkCSP()
      .then(async () => {
        if (!this.versionCheckResult.hasCryptoProvider) {
          return;
        }

        if (this.canAsync(cadesplugin)) {
          await this.getCertificatesList(cadesplugin as CADESPluginAsync,"",new CertificateCheckInfo());
        } else if (this.canSync(cadesplugin)) {
          this.getCertificatesListSync(cadesplugin as CADESPluginSync,"",new CertificateCheckInfo());
        } else {
          return;
        }
      })
      .catch(reason => {
        // TODO Log reason
        notify.show({
          title: "Ошибка при проверке версии криптопровайдера"
        });
      })
      .finally(() => {
        if (!isEmpty(this.versionCheckResult.error)) {
          console.error(this.versionCheckResult.error);
        }
      });
  }

  private async getCertificatesList(
    cadesplugin: CADESPluginAsync,
    filter: string = "",
    appData: CertificateCheckInfo
  ): Promise<void> {
    try {
      // alert("getCertificatesList");
      this.isLoadingCerts = true;
      let store: any;
      try {
        store = await cadesplugin.CreateObjectAsync("CAdESCOM.Store");
      } catch (error) {
        console.debug(error);
      }

      const CAPICOM_CERTIFICATE_FIND_ISSUER_NAME = 2;
      const Adjust = new CertificateAdjuster();

      await store.Open(
        cadesplugin.CAPICOM_CURRENT_USER_STORE,
        cadesplugin.CAPICOM_MY_STORE,
        cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
      );

      const environment = IS_DEV || IS_STAGING;
      const certificates = await store.Certificates;
      const filteredCertificates = await certificates.Find(
        CAPICOM_CERTIFICATE_FIND_ISSUER_NAME, filter, true);
      const certCount = await filteredCertificates.Count;
      for (let i = 1; i <= certCount; ++i) 
      {
        const cert = await filteredCertificates.Item(i);
        const certPublicKey = await cert.PublicKey();
        const certAlgorithm = await certPublicKey.Algorithm;
        const algorithmValue = await certAlgorithm.Value;
        const issuer = await cert.IssuerName;
        
        console.log("Cert store issuer: " + issuer);

        const alg = this.tryGetAlg(algorithmValue);
        const anyIssuer = ISSUER_STOP_LIST.reduce((acc, cur) => {
          if (issuer.includes(cur)) {
            console.log(`WARNING! Issuer is in the ISSUER_STOP_LIST: ${cur}`);
            return false;
          }
          return acc;
        }, true);
        const fullCertInfo = Adjust.GetFullCertInfo(await cert.SubjectName);
        if (alg && (anyIssuer || environment)
         && this.ValidateSubject(fullCertInfo,
          appData._SN,
          appData._G,
          appData._SNILS,
          appData._INN,
          appData._applType,
          appData._OGRN,
          appData._OGRNIP,
          appData._fns)) {
          this.certs.push({
            label: [
              Adjust.GetCertInfoString(await cert.SubjectName, await cert.ValidFromDate),
              `${Adjust.GetSubjectSN(await cert.SubjectName)} ${Adjust.GetSubjectG(await cert.SubjectName)}`
            ],
            value: await cert.Thumbprint,
            cert,
            fullInfo: fullCertInfo,
            alg
          });
        }
      }

      await store.Close();

      try {
        await store.Open(cadesplugin.CADESCOM_CONTAINER_STORE);

        const certs = await store.Certificates;
        const filteredCertificates = await certs.Find(
          CAPICOM_CERTIFICATE_FIND_ISSUER_NAME, filter, true);
        const certCnt = await filteredCertificates.Count;
        for (let j = 1; j <= certCnt; j++) 
        {
          const cert = await filteredCertificates.Item(j);

          const certThumbprint: string = await cert.Thumbprint;

          const certPublicKey = await cert.PublicKey();
          const certAlgorithm = await certPublicKey.Algorithm;
          const algorithmValue = await certAlgorithm.Value;
          const issuer = await cert.IssuerName;
          console.log("Cert container issuer: " + issuer);

          const algorithm = this.tryGetAlg(algorithmValue);
          const anyIssuer = ISSUER_STOP_LIST.reduce((acc, cur) => {
            if (issuer.includes(cur)) {
              console.log(`WARNING! Issuer is in the ISSUER_STOP_LIST: ${cur}`);
              return false;
            }
            return acc;
          }, true);
          const fullCertInfo = Adjust.GetFullCertInfo(await cert.SubjectName);

          if (algorithm && (anyIssuer || environment)
            && this.ValidateSubject(fullCertInfo,
              appData._SN,
              appData._G,
              appData._SNILS,
              appData._INN,
              appData._applType,
              appData._OGRN,
              appData._OGRNIP,
              appData._fns)) {
            if (this.certs.findIndex(c => c.value === certThumbprint) < 0) {
              this.certs.push({
                label: [
                  Adjust.GetCertInfoString(await cert.SubjectName, await cert.ValidFromDate),
                  `${Adjust.GetSubjectSN(await cert.SubjectName)} ${Adjust.GetSubjectG(await cert.SubjectName)}`
                ],
                value: await cert.Thumbprint,
                cert,
                fullInfo: fullCertInfo,
                alg: algorithm
              });
            }
          }
        }
        await store.Close();
      } catch (ex) {
        logError(ex, "Ошибка получения списка сертификатов");
        console.log(ex);
      }
    } catch (err) {
      logError(err, "Ошибка при получении списка сертификатов");
    } finally {
      this.isLoadingCerts = false;
    }
  }

  private getCertificatesListSync(
    cadesplugin: CADESPluginSync,
    filter: string = "",
    appData: CertificateCheckInfo
  ): void {
    try {
      const CAPICOM_CERTIFICATE_FIND_ISSUER_NAME = 2;
      const Adjust = new CertificateAdjuster();

      this.isLoadingCerts = true;

      const store = cadesplugin.CreateObject("CAdESCOM.Store");
      const environment = IS_DEV || IS_STAGING;

      store.Open(
        cadesplugin.CAPICOM_CURRENT_USER_STORE,
        cadesplugin.CAPICOM_MY_STORE,
        cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
      );
      const filteredCertificates = store.Certificates.Find(
        CAPICOM_CERTIFICATE_FIND_ISSUER_NAME, filter, true);

      for (let i = 1; i <= filteredCertificates.Count; ++i) {
        const cert = filteredCertificates.Item(i);

        const certPublicKey = cert.PublicKey();
        const certAlgorithm = certPublicKey.Algorithm;
        const algorithmValue = certAlgorithm.Value;
        const issuer = cert.IssuerName;
        console.log("Cert issuer: " + issuer);

        const algorithm = this.tryGetAlg(algorithmValue);

        const anyIssuer = ISSUER_STOP_LIST.reduce((acc, cur) => {
          if (issuer.includes(cur)) {
            console.log(`WARNING! Issuer is in the ISSUER_STOP_LIST: ${cur}`);
            return false;
          }
          return acc;
        }, true);
        const fullCertInfo = Adjust.GetFullCertInfo(cert.SubjectName);

        if (algorithm && (anyIssuer || environment)
        && this.ValidateSubject(fullCertInfo,
         appData._SN,
         appData._G,
         appData._SNILS,
         appData._INN,
         appData._applType,
         appData._OGRN,
         appData._OGRNIP,
         appData._fns)) {
          this.certs.push({
            label: [
              Adjust.GetCertInfoString(cert.SubjectName, cert.ValidFromDate),
              `${Adjust.GetSubjectSN(cert.SubjectName)} ${Adjust.GetSubjectG(cert.SubjectName)}`
            ],
            value: cert.Thumbprint,
            cert,
            fullInfo: fullCertInfo,
            alg: algorithm
          });
        }
      }
      store.Close();
    } catch (error) {
      logError(error, "Ошибка при загрузке списка сертификатов");
      console.error(` При загрузке сертификатов: ${error}`);
    } finally {
      this.isLoadingCerts = false;
    }
  }

  private ValidateSubject(
    certFullInfo: CertFullInfo,
    _SN: String,
    _G:String,
    _SNILS:String,
    _INN:String,
    _applType: number,
    _OGRN:String,
    _OGRNIP:String,
    _fns:Boolean): Boolean {
      console.log(`cert data: ${certFullInfo.LastName.toLocaleUpperCase()},
      ${certFullInfo.FirstAndMiddleName.toLocaleUpperCase()},
      SNILS - ${certFullInfo.SNILS},
      INN - ${certFullInfo.INN},
      OGRN - ${certFullInfo.OGRN},
      OGRNIP - ${certFullInfo.OGRNIP}`);
      console.log(`app data: ${_SN.toLocaleUpperCase()},
      ${_G.toLocaleUpperCase()},
      SNILS - ${_SNILS},
      INN - ${_INN},
      OGRN - ${_OGRN},
      OGRNIP - ${_OGRNIP}
      typeEntre - ${_applType},
      fns - ${_fns}`);
    return (certFullInfo.LastName.toLocaleUpperCase() === _SN.toLocaleUpperCase() &&
      certFullInfo.FirstAndMiddleName.toLocaleUpperCase() === _G.toLocaleUpperCase() &&
      certFullInfo.SNILS === _SNILS &&
      certFullInfo.INN === _INN &&
      // !(_applType === APPLICANT_TYPES.fl.id && certFullInfo.OGRNIP !== "") && // включили возможность подписания заявления ФЛ подписью ИП
      !(_applType === APPLICANT_TYPES.ip.id && certFullInfo.OGRNIP !== _OGRNIP) &&
      !(_applType === APPLICANT_TYPES.ur.id && certFullInfo.OGRNIP !== "") &&
      !(_applType === APPLICANT_TYPES.ur.id && certFullInfo.OGRN !== "" && certFullInfo.OGRN !== _OGRN) &&
      !(_applType === APPLICANT_TYPES.ur.id && _fns && certFullInfo.OGRN !== _OGRN));
  }

  private async SignCreateAsync(
    cadesplugin: CADESPluginAsync,
    thumbprint: string,
    dataToSign: string
  ): Promise<string | null> {
    const store = await cadesplugin.CreateObjectAsync("CAdESCOM.Store");

    await store.Open(
      cadesplugin.CAPICOM_CURRENT_USER_STORE,
      cadesplugin.CAPICOM_MY_STORE,
      cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
    );

    let storeCerts = await store.Certificates;
    let certificates = await storeCerts.Find(
      cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
      thumbprint
    );
    if ((await certificates.Count) === 0) {

      await store.Close();

      await store.Open(
        cadesplugin.CADESCOM_CONTAINER_STORE
      );

      storeCerts = await store.Certificates;
      certificates = await storeCerts.Find(
        cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
        thumbprint
      );
      if ((await certificates.Count) === 0) {

        const msg = `Сертификат ${thumbprint} не найден (#5)`;
        notify.show({
          title: msg
        });
        logErrorText("Подпись: " + msg);
        return null;
      }
    }
    const cert = await certificates.Item(1);

    const signer = await cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
    await signer.propset_Certificate(cert);

    const signedData = await cadesplugin.CreateObjectAsync(
      "CAdESCOM.CadesSignedData"
    );
    await signedData.propset_ContentEncoding(
      cadesplugin.CADESCOM_BASE64_TO_BINARY
    );
    await signedData.propset_Content(dataToSign);

    let signedMessage = "";
    try {
      signedMessage = await signedData.SignCades(
        signer,
        cadesplugin.CADESCOM_CADES_BES,
        true
      );
    } catch (err) {
      logError(
        err,
        `Ошибка подписи: ${cadesplugin.getLastError(err)} данных: ${dataToSign}`
      );
      console.error(cadesplugin.getLastError(err));
      return null;
    }

    await store.Close();

    return signedMessage;
  }

  private async SignHash(
    cadesplugin: CADESPluginAsync,
    thumbprint: string,
    hash: string
  ): Promise<string | null> {
    const store = await cadesplugin.CreateObjectAsync("CAdESCOM.Store");

    await store.Open(
      cadesplugin.CAPICOM_CURRENT_USER_STORE,
      cadesplugin.CAPICOM_MY_STORE,
      cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
    );

    let storeCerts = await store.Certificates;
    let certs = await storeCerts.Find(
      cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
      thumbprint
    );
    let cert = await certs.Item(1);

    if (!cert) {

      await store.Close();

      await store.Open(
        cadesplugin.CADESCOM_CONTAINER_STORE
      );

      storeCerts = await store.Certificates;
      certs = await storeCerts.Find(
        cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
        thumbprint
      );
      cert = await certs.Item(1);

      if (!cert) {

        const msg = `Сертификат ${thumbprint} не найден (#6)`;
        notify.show({
          title: msg
        });
        logErrorText("Подпись хеша: " + msg);
        return null;
      }
    }

    const signer = await cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
    await signer.propset_Certificate(cert);
    await signer.propset_Options(
      cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN
    );

    const certPublicKey = await cert.PublicKey();
    const certAlgorithm = await certPublicKey.Algorithm;
    const algorithmValue = await certAlgorithm.Value;

    const hashedDataObject = await cadesplugin.CreateObjectAsync(
      "CAdESCOM.HashedData"
    );

    const alg = this.tryGetAlg(algorithmValue);
    if (alg !== "") {
      await hashedDataObject.propset_Algorithm(alg);
    } else {
      logErrorText(
        "Ошибка подписи хеша - не найден нужный алгоритм: " + algorithmValue
      );
      notify.show({
        title: "Невозможно подписать документ этим сертификатом;"
      });
      return null;
    }

    await hashedDataObject.SetHashValue(hash);

    const signedData = await cadesplugin.CreateObjectAsync(
      "CAdESCOM.CadesSignedData"
    );
    await signedData.propset_ContentEncoding(
      cadesplugin.CADESCOM_BASE64_TO_BINARY
    );

    let signedMessage = "";
    try {
      signedMessage = await signedData.SignHash(
        hashedDataObject,
        signer,
        cadesplugin.CADESCOM_CADES_BES
      );
    } catch (err) {
      logError(
        err,
        `Ошибка подписи: ${cadesplugin.getLastError(err)} хэша: ${hash}`
      );
      console.error(cadesplugin.getLastError(err));
      return null;
    }

    await store.Close();

    return signedMessage;
  }

  private SignHashSync(
    cadesplugin: CADESPluginSync,
    thumbprint: string,
    hash: string
  ): string | null {
    const store = cadesplugin.CreateObject("CAdESCOM.Store");

    store.Open(
      cadesplugin.CAPICOM_CURRENT_USER_STORE,
      cadesplugin.CAPICOM_MY_STORE,
      cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
    );

    const certs = store.Certificates.Find(
      cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
      thumbprint
    );

    if (certs.Count === 0) {
      const msg = `Сертификат ${thumbprint} не найден (#7)`;
      console.error(msg);
      logErrorText("Подпись sync " + msg);
      return null;
    }

    const cert = certs.Item(1);
    const signer = cadesplugin.CreateObject("CAdESCOM.CPSigner");
    signer.Certificate = cert;
    // signer. propset_Options(cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN);

    const certPublicKey = cert.PublicKey();
    const certAlgorithm = certPublicKey.Algorithm;
    const algorithmValue = certAlgorithm.Value;

    const hashedDataObject = cadesplugin.CreateObject("CAdESCOM.HashedData");

    const alg = this.tryGetAlg(algorithmValue);
    if (alg !== "") {
      hashedDataObject.Algorithm = alg;
    } else {
      logErrorText(
        "Ошибка подписи хеша sync - не найден нужный алгоритм: " +
          algorithmValue
      );
      notify.show({
        title: "Невозможно подписать документ этим сертификатом;"
      });
      return null;
    }

    hashedDataObject.SetHashValue(hash);

    const signedData = cadesplugin.CreateObject("CAdESCOM.CadesSignedData");
    signedData.ContentEncoding = cadesplugin.CADESCOM_BASE64_TO_BINARY;

    let signedMessage = "";
    try {
      signedMessage = signedData.SignHash(
        hashedDataObject,
        signer,
        cadesplugin.CADESCOM_CADES_BES
      );
    } catch (err) {
      logError(
        err,
        `Ошибка подписи: ${cadesplugin.getLastError(err)} хэша: ${hash}`
      );
      console.error(cadesplugin.getLastError(err));
      return null;
    }

    store.Close();

    return signedMessage;
  }

  private async CalcHashAsync(
    cadesplugin: CADESPluginAsync,
    alg: ValuesOf<CAdESCOM.CADESCOM_HASH_ALGORITHM>,
    dataBase64Str: string
  ): Promise<string | null> {

    // Создаем объект CAdESCOM.HashedData
    var oHashedData = await cadesplugin.CreateObjectAsync("CAdESCOM.HashedData");

    // Алгоритм хэширования нужно указать до того, как будут переданы данные
    await oHashedData.propset_Algorithm(alg);

    // Указываем кодировку данных
    // Кодировка должна быть указана до того, как будут переданы сами данные
    await oHashedData.propset_DataEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY);

    // Передаем данные
    await oHashedData.Hash(dataBase64Str);

    // Получаем хэш-значение
    var sHashValue = await oHashedData.Value;
    
    return sHashValue;
  }

  private CalcHashSync(
    cadesplugin: CADESPluginSync,
    alg: ValuesOf<CAdESCOM.CADESCOM_HASH_ALGORITHM>,
    dataBase64Str: string
  ): string | null {

    // Создаем объект CAdESCOM.HashedData
    var oHashedData = cadesplugin.CreateObject("CAdESCOM.HashedData");

    // Алгоритм хэширования нужно указать до того, как будут переданы данные
    oHashedData.propset_Algorithm(alg);

    // Указываем кодировку данных
    // Кодировка должна быть указана до того, как будут переданы сами данные
    oHashedData.propset_DataEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY);

    // Передаем данные
    oHashedData.Hash(dataBase64Str);

    // Получаем хэш-значение
    var sHashValue = oHashedData.Value;
    
    return sHashValue;
  }

  private tryGetAlg(
    algorithmValue: string
  ): ValuesOf<CAdESCOM.CADESCOM_HASH_ALGORITHM> | "" {
    if (algorithmValue === "1.2.643.7.1.1.1.1") {
      return cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256;
    } else if (algorithmValue === "1.2.643.7.1.1.1.2") {
      return cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512;
    } else if (algorithmValue === "1.2.643.2.2.19") {
      return cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411;
    } else {
      return "";
    }
  }

  private SignCreate(
    cadesplugin: CADESPluginSync,
    thumbprint: string,
    dataToSign: string
  ): string | null {
    try {
      const store = cadesplugin.CreateObject("CAdESCOM.Store");

      store.Open(
        cadesplugin.CAPICOM_CURRENT_USER_STORE,
        cadesplugin.CAPICOM_MY_STORE,
        cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
      );

      const certificates = store.Certificates.Find(
        cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
        thumbprint
      );
      if (certificates.Count === 0) {
        const msg = `Сертификат ${thumbprint} не найден (#8)`;
        notify.show({
          title: msg
        });
        logErrorText("SignCreate: " + msg);
        return null;
      }

      const certificate = certificates.Item(1);

      if (!certificate) {
        const msg = `Сертификат ${thumbprint} не найден (#9)`;
        notify.show({
          title: msg
        });
        logErrorText("SignCreate2: " + msg);
        return null;
      }
      const signer = cadesplugin.CreateObject("CAdESCOM.CPSigner");
      signer.Certificate = certificate;

      const signedData = cadesplugin.CreateObject("CAdESCOM.CadesSignedData");
      signedData.ContentEncoding = cadesplugin.CADESCOM_BASE64_TO_BINARY;
      signedData.Content = dataToSign;

      let signedMessage = "";
      try {
        signedMessage = signedData.SignCades(
          signer,
          cadesplugin.CADESCOM_CADES_BES,
          true
        );
      } catch (err) {
        console.error(cadesplugin.getLastError(err));
        return null;
      }

      store.Close();
      return signedMessage;
    } catch (error) {
      logError(error, "Ошибка при создании подписи");
      console.error(`При формировании подписи: ${error}`);
      return null;
    }
  }

  private async SignVerify(
    cadesplugin: CADESPluginAsync,
    signature: string,
    origData: string
  ): Promise<boolean> {
    const data = await cadesplugin.CreateObjectAsync(
      "CAdESCOM.CadesSignedData"
    );
    try {
      await data.propset_ContentEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY);
      await data.propset_Content(origData);
      await data.VerifyCades(signature, cadesplugin.CADESCOM_CADES_BES, true);
      return true;
    } catch (err) {
      console.error(cadesplugin.getLastError(err));
      return false;
    }
  }

  private SignVerifySync(
    cadesplugin: CADESPluginSync,
    signature: string,
    origData: string
  ): boolean {
    const data = cadesplugin.CreateObject("CAdESCOM.CadesSignedData");
    try {
      data.ContentEncoding = cadesplugin.CADESCOM_BASE64_TO_BINARY;
      data.Content = origData;
      data.VerifyCades(signature, cadesplugin.CADESCOM_CADES_BES, true);
      return true;
    } catch (err) {
      console.error(cadesplugin.getLastError(err));
      return false;
    }
  }

  public async install(
    certificate: string) : Promise<string | undefined> {
      console.log('install')
      if (this.canAsync(cadesplugin)) {
        return await this.InstallAsync(
          cadesplugin,
          certificate,
        );
      } else if (this.canSync(cadesplugin)) {
        return this.Install(cadesplugin, certificate);
      }
  }

  private async InstallAsync(
    cadesplugin: CADESPluginAsync,
    certificate: string,
  ): Promise<string> {
    try {
      const enroll = await cadesplugin.CreateObjectAsync("X509Enrollment.CX509Enrollment");
      const AllowNone = 0;
      const AllowUntrusted = 4;
      const CADESCOM_SkipInstallToStore = 0x10000000;
      const UserStore = 1;
      const XCN_CRYPT_STRING_ANY = 0x7;
      //enroll.InitializeFromRequest(UserStore);
      enroll.Initialize(UserStore);
      //const response = await enroll.InstallResponse(AllowNone | CADESCOM_SkipInstallToStore, certificate, XCN_CRYPT_STRING_ANY, "");
      const response = await enroll.InstallResponse(AllowNone , certificate, XCN_CRYPT_STRING_ANY, "");
      return response;  
    } catch (error) {
      logError(error, "Ошибка при создании установки async");
      console.error(`Ошибка при создании установки async: ${JSON.stringify(error)}`); 
      return JSON.stringify(error);   
    }
  }

  private Install(
    cadesplugin: CADESPluginSync,
    certificate: string,
  ) : string {
    try {
      const enroll = cadesplugin.CreateObject("X509Enrollment.CX509Enrollment");
      const AllowNone = 0;
      const CADESCOM_SkipInstallToStore = 0x10000000;
      const UserStore = 1;
      const XCN_CRYPT_STRING_ANY = 0x7;
      enroll.Initialize(UserStore);
      //const response = enroll.InstallResponse(AllowNone | CADESCOM_SkipInstallToStore, certificate, XCN_CRYPT_STRING_ANY, "");
      const response = enroll.InstallResponse(AllowNone , certificate, XCN_CRYPT_STRING_ANY, "");
      return response;  
    } catch (error) {
      logError(error, "Ошибка при создании установки");
      console.error(`Ошибка при создании установки: ${JSON.stringify(error)}`);  
      return JSON.stringify(error); 
    }
  }

  public async signRequest(
    dataAsBase64: string,
    certThumbprint: string,
    detached: boolean = true
  ): Promise<string | null> {
    if (this.canAsync(cadesplugin)) {
      return await this.SignRequestAsync(
        cadesplugin,
        certThumbprint,
        dataAsBase64,
        detached
      );
    } else if (this.canSync(cadesplugin)) {
      return this.SignRequest(
        cadesplugin,
        certThumbprint,
        dataAsBase64,
        detached
      );
    } else {
      return null;
    }
  }

  private async SignRequestAsync(
    cadesplugin: CADESPluginAsync,
    thumbprint: string,
    dataToSign: string,
    detached: boolean = true
  ): Promise<string | null> {
    const store = await cadesplugin.CreateObjectAsync("CAdESCOM.Store");
    await store.Open(
      cadesplugin.CAPICOM_CURRENT_USER_STORE,
      cadesplugin.CAPICOM_MY_STORE,
      cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
    );

    let storeCerts = await store.Certificates;
    let certs = await storeCerts.Find(
      cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
      thumbprint
    );
    let cert = await certs.Item(1);
    if (!cert) {

      await store.Close();

      await store.Open(
        cadesplugin.CADESCOM_CONTAINER_STORE
      );

      storeCerts = await store.Certificates;
      certs = await storeCerts.Find(
        cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
        thumbprint
      );
      cert = await certs.Item(1);
      if (!cert) {

        const msg = `Сертификат ${thumbprint} не найден (#5)`;
        notify.show({
          title: msg
        });
        logErrorText("Подпись: " + msg);
        return null;
      }
    }

    const signer = await cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
    await signer.propset_Certificate(cert);
    const signedData = await cadesplugin.CreateObjectAsync(
      "CAdESCOM.CadesSignedData"
    );
    await signedData.propset_ContentEncoding(
      cadesplugin.CADESCOM_BASE64_TO_BINARY
    );
    await signedData.propset_Content(dataToSign);
    let signedMessage = "";
    try {
      signedMessage = await signedData.SignCades(
        signer,
        cadesplugin.CADESCOM_CADES_BES,
        detached
      );
    } catch (err) {
      logError(
        err,
        `Ошибка подписи: ${cadesplugin.getLastError(err)} данных: ${dataToSign}`
      );
      console.error(cadesplugin.getLastError(err));
      return null;
    }

    await store.Close();

    return signedMessage;
  }

  private SignRequest(
    cadesplugin: CADESPluginSync,
    thumbprint: string,
    dataToSign: string,
    detached: boolean = true
  ): string | null {
    try {
      const store = cadesplugin.CreateObject("CAdESCOM.Store");

      store.Open(
        cadesplugin.CAPICOM_CURRENT_USER_STORE,
        cadesplugin.CAPICOM_MY_STORE,
        cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
      );

      const certificates = store.Certificates.Find(
        cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
        thumbprint
      );
      if (certificates.Count === 0) {
        const msg = `Сертификат ${thumbprint} не найден (#8)`;
        notify.show({
          title: msg
        });
        logErrorText("SignCreate: " + msg);
        return null;
      }

      const certificate = certificates.Item(1);

      if (!certificate) {
        const msg = `Сертификат ${thumbprint} не найден (#9)`;
        notify.show({
          title: msg
        });
        logErrorText("SignCreate2: " + msg);
        return null;
      }
      const signer = cadesplugin.CreateObject("CAdESCOM.CPSigner");
      signer.Certificate = certificate;

      const signedData = cadesplugin.CreateObject("CAdESCOM.CadesSignedData");
      signedData.ContentEncoding = cadesplugin.CADESCOM_BASE64_TO_BINARY;
      signedData.Content = dataToSign;

      let signedMessage = "";
      try {
        signedMessage = signedData.SignCades(
          signer,
          cadesplugin.CADESCOM_CADES_BES,
          detached
        );
      } catch (err) {
        console.error(cadesplugin.getLastError(err));
        return null;
      }

      store.Close();
      return signedMessage;
    } catch (error) {
      logError(error, "Ошибка при создании подписи");
      console.error(`При формировании подписи: ${error}`);
      return null;
    }
  }
}
