






























































































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import {mapActions, mapGetters, mapState} from "vuex";

import ProposalForm from "@/components/ProposalForm.vue";
import ProposalFormDocument from "@/components/ProposalFormDocument.vue";
import ItemDocument from "@/components/ItemDocument.vue";
import FormBox from "@/components/UI/form/FormBox.vue";
import Button from "@/components/UI/Button.vue";
import Checkbox from "@/components/UI/form/Checkbox.vue";
import IconBase from "@/components/UI/IconBase.vue";
import Cert from "@/components/UI/icon/Cert.vue";
import NotCert from "@/components/UI/icon/NotCert.vue";
import Products from "@/components/Products.vue";
import {Tab, Tabs} from "@/components/Tabs";
import {InitialFileTypes} from '@/constants/InitialFileTypes';
import {IDocInfo} from '@/store/modules/application/application.interfaces';
import InputSwitch from "@/components/UI/form/InputSwitch.vue";
import {UpdRequestStatuses} from '@/constants/UpdRequestStatuses';
import ModalWithField from "@/components/ModalWithField.vue";
import { isEmpty } from "lodash";
import { POSITION } from "vue-toastification";
import { TOAST_OPTIONS } from "@/constants";

@Component({
  computed: {
    ...mapState("application", [
      "isDocumentDownload",
      "activeTab",
      "isDocumentsCollected",
      "isCertificateRequested",
      "certificate",
      "statusId",
      "isEdit",
      "isUPDrequested",
      "isSendUPDtoEDM",
      "edmTypes",
      "hideUtd",
      "updStatusId",
      "updStatus",
      "updocument",
      "isFNSIssueAvailable",
      "uploadFormControls",
      "privateKeyExportPolicy",
      "isPdnUsingProhibited",
    ]),
    ...mapState("applicationListState", ["isArchive"]),
    ...mapGetters("application", [
      "getCollectedDocuments",
      "getDocumentsToUpload",
      "getAnotherDocuments",
      "getApplicationInfo",
      "getIsSystemProcessing",
      "getIsFormValid",
      "getDownloadDocuments",
      "getApplicationStatuses"
    ]),
    ...mapGetters("productsState", ["getHasTariffFNS"]),
    ...mapGetters("authorizationState", ["isManagerPartner"])
  },
  methods: {
    ...mapActions("application", [
      "setActiveTab",
      "genRequestFile",
      "downloadHandler",
      "downloadRaw",
      "install",
      "toggleSendUPDtoEDM",
      "requestUPD",
      "handleUploadReq",
      "changePrivateKeyExportPolicy",
      "clearSMEVChecks",
      "setEdmTypeId",
      "openInstructionDocument",
    ])
  },
  components: {
    ProposalForm,
    ProposalFormDocument,
    Products,
    Button,
    Checkbox,
    ItemDocument,
    IconBase,
    Cert,
    NotCert,
    Tabs,
    Tab,
    InputSwitch,
    ModalWithField
  }
})
export default class FormRequest extends Vue {
  /* data */

  /* computed */
  public hideUtd!: boolean;
  public statusId!: number;
  public filesDownload!: [];
  public certificate!: IDocInfo[];
  public isUPDrequested!: boolean;
  public isSendUPDtoEDM!: boolean;
  public getHasTariffFNS!: boolean;
  public getApplicationStatuses!: any;

  private static get getInitialFileTypes() {
    return InitialFileTypes;
  }

  private get getUPDRequestStatuses() {
    return UpdRequestStatuses;
  }

  private get getLicenses() {
    return this.certificate.filter(c => c.licenseType != null);
  }
  
  /* methods */
  private requestUPD!: () => void;
  private genRequestFile!: () => void;
  private clearSMEVChecks!: () => void;
  private handleUploadReq!: (evt: any) => void;
  private install!: (certificate: string) => string | undefined;
  private downloadRaw!: (docInfo: IDocInfo) => string;
  private downloadHandler!: (certificate: IDocInfo) => void;  
  private setEdmTypeId!: (edmTypeId: number | null) => void;

  private isProductInOrder(docInfos: IDocInfo[], type: InitialFileTypes): boolean {
    return this.getInfoDoc(docInfos, type) != undefined;
  }

  private getInfoDoc(docInfos: IDocInfo[], type: InitialFileTypes) : any {
    return docInfos.find(d => d.typeId == type);
  }

  private async installCertificate(docInfo: IDocInfo) {
    const certificate = await this.downloadRaw(docInfo);
    if (certificate.length > 0) {
      const res = await this.install(certificate);
      console.log(`install res:${res}`);
      if(!res || isEmpty(res)){
        Vue.$toast.success("Сертификат успешно установлен",TOAST_OPTIONS.Success);
        return;
      }
    }
    Vue.$toast.error("Что-то пошло не так. Попробуйте установить сертификат вручную", {
                position: POSITION.TOP_LEFT,
                timeout: 5000,
                closeOnClick: false,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
  }

  private handleGetCertificate() {
    if (
      !this.hideUtd
      && !this.isUPDrequested
      && (this.statusId === this.getApplicationStatuses.CertificateReady.id
      || this.statusId === this.getApplicationStatuses.EDORegistration.id
      || this.statusId === this.getApplicationStatuses.WaitingForOriginals.id
      || this.statusId === this.getApplicationStatuses.OriginalsIncorrect.id
      || this.statusId === this.getApplicationStatuses.Closed.id)
    ) {
      this.$modal.show("dialog", {
        title: "ВНИМАНИЕ",
        text: "Обращаем ваше внимание, что при скачивании сертификата отправится запрос на формирование УПД.<br />" + 
              "Документ УПД будет прикреплен в эту заявку.<br />" + 
              "Вы уверены, что хотите продолжить?",
        buttons: [
          {
            title: "Отмена",
          },
          {
            title: "Продолжить",
            handler: () => {
              this.requestUPD();
              this.downloadHandler(this.certificate[0]);
              this.$modal.hide("dialog");
            }
          }
        ]
      });
      //this.downloadHandler(this.certificate[0]);
    }
    else {
      this.downloadHandler(this.certificate[0]);
    }
  };

  private uploadRequestHandler(evt: any) {
    if (this.getHasTariffFNS) {
      this.$modal.show("dialog", {
        title: "ВНИМАНИЕ",
        text: "В заявке присутствует тариф ФНС.<br />" + 
              "В процессе загрузки будет предложено подписать файл запроса",
        buttons: [
          // {
          //   title: "Отмена",
          // },
          {
            title: "Продолжить",
            handler: () => {
              this.handleUploadReq(evt);
              this.$modal.hide("dialog");
            }
          }
        ]
      });
    } else {
      this.handleUploadReq(evt);
    }
  }

  /* watchers */
  @Watch("isSendUPDtoEDM")
  isSendUPDtoEDMWatcher() {
    if (this.isSendUPDtoEDM) {
      this.$modal.show("modal-edo-type")
    } else {
      this.setEdmTypeId(null);
    }
  }

  /* life circle methods */
  public destroyed() {
    this.setEdmTypeId(null);
    this.clearSMEVChecks();
  }
}
