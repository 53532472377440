import {ActionTree} from "vuex";
import {fetch} from "@/services/api";
import JSZip from "jszip";
import Compressor from "compressorjs";
import router from "@/router";
import download from "downloadjs";
import {isBoolean, isEmpty, isNull, isNumber, isObject, isString, template,} from "lodash";
import {app} from "@/main";
import Vue from "vue";
import moment from "moment";

import {AxiosResponse} from "axios";

import {up, base64toBlob, blobToUrl} from "@/helpers";
import {extname} from "@/utils";
import {validation, validationFile} from "@/services";
import {cert} from "../cert";

import {application, crypto} from "./index";

import {logError, logErrorText} from "@/services/api/serverLogger";

import {InitialFileTypes} from "@/constants/InitialFileTypes";
import {IApplicationState, TFieldUploadName, TControlName, IFieldsFromBasisApi, TPayerType, TTypeEntre} from "./application.interfaces";
import {RootState} from "../../root.interface";
import { scrollToInvalidFormItem } from "@/helpers/scrollToInvalidFormItem";
import { STATUSES, /*ANALIT_CENTR_OGRN,*/ BASIC_STAUSES, PAYER_TYPES, IDENTIFICATION_DOCUMENT_TYPES, APPLICATION_TYPES, DOWNLOAD_DOCUMENTS, fnsOGRN, APPLICANT_TYPES } from "./application.constants";
import { IDENTIFICATION_KINDS } from "@/constants/";
import { fileToBase64, base64ToFile } from "@/helpers";
import { TOAST_OPTIONS } from "@/constants";
import { IRulesValidation } from "@/services/validation";

import ToastWithCopy from '@/components/UI/ToastWithCopy.vue'

import {
    CLEAR_FORM_CONTROLS,
    CLEAR_REGIONS,
    CLEAR_UPLOAD_FORM_CONTROLS,
    NEXT_ACTIVE_TAB,
    RESET_READONLY_FIELDS_KEY,
    SET_ACTIVE_TAB,
    SET_ARCHIVED,
    SET_CERTIFICATE,
    SET_CHANGE_DATE,
    SET_COMMENT,
    SET_CREATION_DATE,
    SET_CURRENT_CERT_THUMBPRINT,
    SET_DIRECTOR,
    SET_DISABLED,
    SET_DOCUMENT_DOWNLOAD,
    SET_ERROR_MESSAGE,
    SET_EXTERNAL_SOURCE_PKCS_10,
    SET_FILES_DOWNLOAD,
    SET_FORM_CONTROL,
    SET_FORM_CONTROLS_FILE,
    SET_HIDE_UTD,
    SET_IS_CERTIFICATE_REQUESTED,
    SET_IS_DOCUMENTS_COLLECTED,
    SET_IS_EDIT,
    SET_IS_EDIT_MANAGERCOMMENT,
    SET_IS_EDIT_UPLOAD,
    SET_IS_FORM_VALID,
    SET_IS_MANAGERCOMMENT_EDITABLE,
    SET_IS_SUBMITED,
    SET_IS_SUBMITED_MANAGERCOMMENT,
    SET_IS_UPLOAD_FORM_LOADED,
    SET_LAST_PAYMENT_DATE,
    SET_MANAGERCOMMENT,
    SET_MANAGER_ID,
    SET_OPTIONS_COUNTRY,
    SET_OPTIONS_REGION,
    SET_ORDER_ID,
    SET_ORG_NAME,
    SET_ORG_NAME_DISABLED,
    SET_ORG_PATRONYMIC,
    SET_ORG_PATRONYMIC_DISABLED,
    SET_ORG_SURNAME,
    SET_ORG_SURNAME_DISABLED,
    SET_PAYMENT_STATUS_DESCRIPTION,
    SET_PAYMENT_STATUS_ID,
    SET_POSITION_DIRECTOR, SET_POSITION_DIRECTOR_DISABLED,
    SET_READONLY_FIELDS_KEY,
    SET_READONLY_FIELDS_KEY_ALL,
    SET_SEND_UPD_TO_EDM,
    SET_STATUS,
    SET_STATUS_ID,
    SET_TYPE_SELECTION,
    SET_UPD_DOCUMENT,
    SET_UPD_REQUESTED,
    SET_UPD_STATUS,
    SET_UPD_STATUS_ID,
    SET_IS_KKMRECEIPT_LAUNCHABLE,
    SET_OPTIONS_IDENTIFICATION_DOCUMENT_TYPES,
    SET_IS_FNS_ISSUE_AVAILABLE,
    SET_PRIVATEKEY_EXPORT_POLICY,
    SET_CHECK_CERT_IN_FNS_MESSAGE,
    SET_NEED_TO_ATTACH_REVOKE,
    SET_SCANS_FROM_PREAPP,
    SET_SMEV_CHECKS,
    SET_DOCUMENT_UPLOAD_REQUIRED,
    SET_EDM_TYPES,
    SET_EDM_TYPE_ID,
    SET_FIELDS_FROM_BASIS_API,
    SET_ANOTHER_UPLOAD,
    SET_FNS_EXISTING_CERTS,
    CLEAN_FNS_EXISTING_CERTS,
    SET_NEW_CERT_REQUEST,
    SET_SIGNED_CERT_REQUEST,
    CLEAR_NEW_CERT_REQUEST,
    CLEAR_SIGNED_CERT_REQUEST,
    SET_NEW_CERT_REVOKE,
    CLEAR_NEW_CERT_REVOKE,
    SET_SIGNED_CERT_REVOKE,
    CLEAR_SIGNED_CERT_REVOKE,
    DEFINE_FIELDS_ACTIVE,
    DEFINE_PAYER_FIELDS_ACTIVE,
    DEFINE_IDENTIFICATION_DOCUMENT_FIELDS_ACTIVE,
    TOGGLE_IS_FILLED_BY_ORGN,
    SET_REQUEST_GUID,
    CLEAR_FIELD_VALUE,
    SET_SIG_CHECK_STATUS,
    SET_SIG_ISSUER_CN,
    SET_FILIAL_CLIENT_LINK,
    SET_IS_LINK_ERROR,
    SET_QR_URL,
    SET_IS_PDN_USING_PROHIBITED,
    SET_FILIAL_INFO,
} from "./application.mutations";
import {CertState} from "../cert/interfaces/cert.state";
import {IDocumentsResponse} from "./application.interfaces";
import {POSITION} from "vue-toastification";
import { CertificateCheckInfo } from "@/@types/certificateCheckInfo";

const compressorAsync = (
    file: File,
    options?: Omit<Compressor.Options, "success" | "error">
): Promise<{ name: string; blob: Blob }> => {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            maxWidth: 1920,
            mimeType: "image/jpeg",
            quality: 0.6,
            success: (blob) => resolve({name: file.name, blob}),
            error: reject,
            ...options,
        });
    });
};

const compressorBlobAsync = (
    file: Blob,
    fileName: string,
    options?: Omit<Compressor.Options, "success" | "error">
): Promise<{ name: string; blob: Blob }> => {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            maxWidth: 1920,
            mimeType: "image/jpeg",
            quality: 0.6,
            success: (blob) => resolve({name: fileName, blob}),
            error: reject,
            ...options,
        });
    });
};

const isFormValid = (controls: any) => {
    const errors: string[] = [];
    Object.keys(controls).forEach((key: string) => {
        const control = {...controls[key]};
        if (
            (!control.isBlur && control.isBlur !== undefined && control.active) ||
            (control.required && !control.isValid && control.active)
        ) {
            errors.push(key);
        }
    });

    return errors.length === 0;
};

const getPayerFieldName = (nameControl: string) => {
    const name = nameControl.replace("payer", "");
    return name.charAt(0).toLowerCase() + name.slice(1);
};

export const actions: ActionTree<IApplicationState, RootState> = {
    cryptoLoad() {
        crypto.load();
    },

    clearFromControlsHandler({commit, dispatch}: any) {
        commit(CLEAR_FORM_CONTROLS);
        commit(CLEAR_REGIONS);
        commit(SET_TYPE_SELECTION, "");
        commit(RESET_READONLY_FIELDS_KEY);
        commit(SET_IS_SUBMITED, false);
        dispatch("clearCertRequest", null, {root: true});
    },

    clearErrorMessage({commit}: any) {
        commit(SET_ERROR_MESSAGE, null);
    },

    clearManagerComment({commit}) {
        commit(SET_MANAGERCOMMENT, null);
    },

    clearSMEVChecks({commit}) {
        commit(SET_SMEV_CHECKS, []);
    },

    setCurrentCertThumbprint({commit}: any, val: string) {
        commit(SET_CURRENT_CERT_THUMBPRINT, val);
    },

    setActiveTab({commit, state}: any, num: number) {
        if (num !== state.activeTab) {
            commit(SET_ACTIVE_TAB, num);
        }
    },

    nextActiveTab({commit}: any, scrollUp: boolean = false) {
        if (scrollUp) {
            up(commit.bind(null, NEXT_ACTIVE_TAB));
        } else {
            commit(NEXT_ACTIVE_TAB);
        }
    },

    typeSelectionHandler({commit, state}, typeEntre: string) {
        const innControl = {...state.formControls.inn};

        innControl.value = "";
        //innControl.isValid = true;
        innControl.isBlur = false;

        if (typeEntre === "ur") {
            innControl.rules = {
                inn: true,
                empty: true,
                minLength: 10,
            };
        } else {
            innControl.rules = {
                inn: true,
                empty: true,
                minLength: 12,
            };
        }

        if (state.fieldsReadonlyKeys.length) {
            commit(CLEAR_FORM_CONTROLS);
            commit(RESET_READONLY_FIELDS_KEY);
        } else {
            commit(SET_FORM_CONTROL, {control: innControl, name: "inn"});
        }

        if (state.isFilledByOGRN) {
            commit(TOGGLE_IS_FILLED_BY_ORGN);
        }
        
        if (!isEmpty(state.fieldsFromBasisApi)) {
          commit(CLEAR_FORM_CONTROLS);
          commit(SET_FIELDS_FROM_BASIS_API, {});
        }

        commit(SET_TYPE_SELECTION, typeEntre);
        commit(DEFINE_FIELDS_ACTIVE, typeEntre);
    },
    edit({commit, state, dispatch}: any) {
        if (state.activeTab === 1 || state.activeTab === 2) {
            commit(SET_IS_EDIT, true);
            commit(SET_DISABLED, false);
            commit(SET_DOCUMENT_DOWNLOAD, false);
            commit(SET_IS_FORM_VALID, true);
            dispatch("checkIsDirector");
        }

        commit(SET_IS_SUBMITED, false);
        commit(SET_IS_DOCUMENTS_COLLECTED, false);
        commit(SET_IS_EDIT_UPLOAD, true);
        commit(CLEAR_UPLOAD_FORM_CONTROLS);
        commit(SET_IS_CERTIFICATE_REQUESTED, false);
    },
    rejectManagerComment({commit, state}: any, evt: any) {
        commit(SET_IS_EDIT_MANAGERCOMMENT, false);    
    },
    inputHandler({commit, state}: any, evt: any) {
        const {name, checked, type, value} = evt.target;
        const control = {...state.formControls[name]};
        const isCheckbox = type === "checkbox";
        if (name === "managerComment") {
            commit(SET_IS_EDIT_MANAGERCOMMENT, true);    
        } else if (name === "payerSubjectType" && evt.type=="change") {
            commit(DEFINE_PAYER_FIELDS_ACTIVE, value);
        }
        if ((evt.type === "change" && name.startsWith("region")) ||
            (evt.type === "change" && name.startsWith("identification")) ||
            (evt.type === "change" && name.startsWith("countryId")) ||
            (!name.startsWith("region") && !name.startsWith("identification") && !name.startsWith("countryId"))) {
            control.value = isCheckbox ? checked : value;

            if (name === "fullNameOrg" || name === "shortNameOrg") {
                control.value = value.replaceAll("\n\r", " ").replaceAll("\r\n", " ").replace(/[\n\r]/g, " ");
            } else if (typeof control.value === "string") {
                control.value = value.replace(/\s+/g, " ").replace(/^\s/, "");
            }
        }

        control.isTouched = true;

        if (!control.checkboxId) {
            control.isBlur = true;
            const {isValid, invalidityInfo} = validation(control.value, control.rules);
            control.isValid = isValid;
            control.invalidityInfo = !isValid ? invalidityInfo : "";
        }
        
        if(name.startsWith("identificationDocumentTypeId") && evt.type=="change"){
            const passportCodeControl = {...state.formControls["passportDivisionCode"]}; 
            const docSeriesControl = {...state.formControls["docSeries"]};
            const serialDocControl = {...state.formControls["serialDoc"]};
            const countryControl = {...state.formControls["countryId"]};
            if(control.value !== 1){
                passportCodeControl.active=false;
                docSeriesControl.active=true;
                countryControl.disabled=false;
                serialDocControl.rules = {empty:true};
            }   else {
                passportCodeControl.active=true;
                passportCodeControl.isValid = false;
                passportCodeControl.invalidityInfo = "";
                docSeriesControl.active=false;
                countryControl.value=193;
                countryControl.disabled=true;
                serialDocControl.rules = {minLength:10,empty:true};
            }
            serialDocControl.value="";
            serialDocControl.isValid = false;
            serialDocControl.invalidityInfo = "";
            docSeriesControl.value="";
            commit(SET_FORM_CONTROL, {control:passportCodeControl, name:'passportDivisionCode'});
            commit(SET_FORM_CONTROL, {control:docSeriesControl, name:'docSeries'});
            commit(SET_FORM_CONTROL, {control:serialDocControl, name:'serialDoc'});
            commit(SET_FORM_CONTROL, {control:countryControl, name:'countryId'});
        }

        if (type === "multiselect") {
            commit(SET_FORM_CONTROL, {control: {value: JSON.parse(JSON.stringify(control.value)), ...control}, name});
        } else {
            commit(SET_FORM_CONTROL, {control, name});
        }
    },

    changeForInfoSys({commit, state}: any, evt: any){
        const control = {...state.formControls["forInfoSys"]};    
        control.value = evt;
        const name = "forInfoSys";
        commit(SET_FORM_CONTROL, {control, name});
    },

    changePrivateKeyExportPolicy({commit, state}: any, evt: any){
        commit(SET_PRIVATEKEY_EXPORT_POLICY,evt? 1: -1);
    },

    checkIsDirector({commit, state}: any) {
        let checked = true;
        if (checked && state.formControls['surnameOrg'].value !== state.formControls['surname'].value) {
            checked = false;
        }
        if (checked && state.formControls['nameOrg'].value !== state.formControls['name'].value) {
            checked = false;
        }
        if (checked && state.formControls['patronymicOrg'].value !== state.formControls['patronymic'].value) {
            checked = false;
        }
        if (checked && state.formControls['positionDirector'].value !== state.formControls['position'].value) {
            checked = false;
        }
        if (state.formControls['surnameOrg'].value === '' && state.formControls['surname'].value === ''
          && state.formControls['nameOrg'].value === '' && state.formControls['name'].value === ''
          && state.formControls['patronymicOrg'].value === '' && state.formControls['patronymic'].value === ''
          && state.formControls['positionDirector'].value === '' && state.formControls['position'].value === '') {
            checked = false;
        }
        if(checked && state.isEdit){
            commit(SET_ORG_SURNAME_DISABLED, true);
            commit(SET_ORG_NAME_DISABLED, true);
            commit(SET_ORG_PATRONYMIC_DISABLED, true);
            commit(SET_POSITION_DIRECTOR_DISABLED, true);
        }
        commit(SET_DIRECTOR, checked);
    },

    changeIsDirector({ commit, state, dispatch }, { checked }) {
        const isAutoFillFromBasis = !isEmpty(state.fieldsFromBasisApi);
        commit(SET_DIRECTOR, checked);

        if (checked){
            commit(SET_ORG_SURNAME, state.formControls['surname'].value);
            commit(SET_ORG_NAME, state.formControls['name'].value);
            commit(SET_ORG_PATRONYMIC, state.formControls['patronymic'].value);
            commit(SET_POSITION_DIRECTOR, state.formControls['position'].value);
            commit(SET_ORG_SURNAME_DISABLED, true);
            commit(SET_ORG_NAME_DISABLED, true);
            commit(SET_ORG_PATRONYMIC_DISABLED, true);
            commit(SET_POSITION_DIRECTOR_DISABLED, true);
        } else {
            commit(SET_ORG_SURNAME, isAutoFillFromBasis ? state.formControls.surnameOrg.value : '');
            commit(SET_ORG_NAME, isAutoFillFromBasis ? state.formControls.nameOrg.value : '');
            commit(SET_ORG_PATRONYMIC, isAutoFillFromBasis ? state.formControls.patronymicOrg.value : '');
            commit(SET_POSITION_DIRECTOR, isAutoFillFromBasis ? state.formControls.positionDirector.value : '');
            commit(SET_ORG_SURNAME_DISABLED, false);
            commit(SET_ORG_NAME_DISABLED, false);
            commit(SET_ORG_PATRONYMIC_DISABLED, false);
            commit(SET_POSITION_DIRECTOR_DISABLED, false);
        }

        if (!state.isFormValid) {
            dispatch('validateField', 'surnameOrg');
            dispatch('validateField', 'nameOrg');
            dispatch('validateField', 'patronymicOrg');
            dispatch('validateField', 'positionDirector');
        }
    },

    validateField({commit, state}: any, name: string) {
        const control = {...state.formControls[name]};

        control.isTouched = true;
        control.isBlur = true;
        const {isValid, invalidityInfo} = validation(control.value, control.rules);
        control.isValid = isValid;
        if (!isValid) {
            control.invalidityInfo = invalidityInfo;
        }

        commit(SET_FORM_CONTROL, {control, name});
    },

    checkValidity({commit, state}: any) {
        Object.keys(state.formControls).forEach((name: string) => {
            const control = {...state.formControls[name]};
            if (control.active && control.hasOwnProperty("isValid")) {
                control.isTouched = true;
                control.isBlur = true;

                const {isValid, invalidityInfo}  = validation(control.value, control.rules);
                control.isValid = isValid;
                if (!isValid) {
                    control.invalidityInfo = invalidityInfo;
                }

                commit(SET_FORM_CONTROL, {control, name});
            }
        });
    },

    autoFillFields({commit, state, dispatch}, {fields, disableFields}: any) {
        if (!state.formControls.typeEntre.value) {
            throw new Error('Ошибка автозаполнения формы: не удалось определить typeEntre');
        }

        const typeEntre: TTypeEntre = state.formControls.typeEntre.value;

        const innLength: number = typeEntre === "ur" ? 10 : 12;

        commit(RESET_READONLY_FIELDS_KEY);

        if (fields.hasOwnProperty("error")) {
            throw new Error(fields["error"]);
        }
        
        let _forInfoSys: boolean = ["ur", "ip"].includes(typeEntre);
        const hasFieldsFromBasisApi = !isEmpty(state.fieldsFromBasisApi);

        for (const key in fields) {
            const value = fields[key];


            if (key === "personInn") {
                if (typeEntre === "ur") {
                    if (!isNull(value) && !isEmpty(value)) {//теперь personInn может быть null  в обезличеном
                        _forInfoSys = false;
                    }
                    if (value.length === 12 && value.startsWith("00")) {
                        throw new Error(
                            `Ошибка автозаполнения формы. Поле ИНН физ. лица заполнено некорректно`
                        );
                    }
                }
            }

            if (key === "snils") {
                if (["ur", "ip"].includes(typeEntre)) {
                    if (!isNull(value) && !isEmpty(value)) {
                        _forInfoSys = false;
                    }
                } else {
                    if (isNull(value) || isEmpty(value)) {
                        throw new Error(
                            `Ошибка автозаполнения формы. Поле СНИЛС не заполнено`
                        );
                    }
                }
            }
                        
            if (["surname", "name", "patronymic", "position"].includes(key)) {
                if (["ur", "ip"].includes(typeEntre)) {
                    if (!isNull(value) && !isEmpty(value)) {
                        _forInfoSys = false;
                    }
                }
            }

            if (key === 'isDirector') {
                const control = {
                    ...state.formControls[key],
                    value
                }

                commit(SET_FORM_CONTROL, {control, name: key});
            }
                        
            if (
                APPLICANT_TYPES[typeEntre].activeFields.includes(key) &&
                !isNull(value) &&
                (isNumber(value) || !isEmpty(value))
            ) {
                let _value = key.startsWith("identificationKind") ? value + 1 : value;
                                
                const control = {
                    ...(state.formControls as any)[key],
                    value: _value,
                    disabled: disableFields,
                };

                control.isBlur = true;
                control.isTouched = true;

                const {isValid, invalidityInfo} = validation(control.value, control.rules);
                control.isValid = isValid;
                if (!isValid) {
                    control.invalidityInfo = invalidityInfo;
                }
                            

                if (
                    !control.isValid ||
                    (key === "inn" && control.value.length !== innLength)
                ) {
                    commit(CLEAR_FORM_CONTROLS);
                    dispatch("clearCertRequest", null, {root: true});

                    throw new Error(
                        `Ошибка автозаполнения формы. Поле ${key} заполнено некорректно`
                    );
                }

                commit(SET_FORM_CONTROL, {control, name: key});
                !hasFieldsFromBasisApi && commit(SET_READONLY_FIELDS_KEY, key);
            }
        }

        // Если два раза производилось автозаполнение, то во второй раз поля руководителя не disabled
        if (state.formControls.isDirector.value) {
            dispatch('changeIsDirector', true);
        }
        
        if (_forInfoSys && !hasFieldsFromBasisApi) {//обезличенный запрос
            const control = {
                ...state.formControls["forInfoSys"],
                value: true,
                disabled: true,
            }; 
            commit(SET_FORM_CONTROL, {control, name: "forInfoSys"});   
        } else if (!hasFieldsFromBasisApi) {
            const control = {
                ...state.formControls["forInfoSys"],
                value:false,
                disabled: true,
            }; 
            commit(SET_FORM_CONTROL, {control, name: "forInfoSys"});     
        }
    },

    async handleUploadReq({dispatch}: any, evt: any) {
        let fieldsNoEqualErrorCode = 400;
        let improperStatusErrorCode = 404;
        let iAppId = document.getElementsByClassName('uploadReq')[0].id;
        const file = evt.target.files[0];
        file.iAppId = iAppId;
        if (file) {
            try {
                await dispatch("uploadReq", file, {root: true});
            } catch (err) {
                const error = (err as any);
                let text = "";
                let message = error;
                try {
                    message = JSON.parse(JSON.stringify(error)).message;
                    if (message.includes(fieldsNoEqualErrorCode.toString())) {
                        text = "Поля в файле запроса и заявке не совпадают \n\n Исправьте данные в файле запроса или заявке";
                    } else if (message.includes(improperStatusErrorCode.toString())) {
                        text = "Не удаётся подгрузить файл \n\n Заявка должна быть в статусе \"Генерация запроса\"";
                    }
                    if(typeof(error) !== 'undefined')
                        logError(error);
                }
                catch(error)
                {
                }
                Vue.$toast.error(text, {
                    position: POSITION.TOP_LEFT,
                    timeout: 15000,
                    closeOnClick: false,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: false,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });

                throw error;
            }
        }
    },

    async transferScansFromPreApp({commit, state}: any) {
        state.scansFromPreApp.forEach(async (s: any) => {
            let file: null | File = null;    
            try {
                const res = await fetch.get(`api/InternalPreApplication/getScan/${s.id}`);
                if (res.status === 200 && !res.data.error) {
                    const {fileJS} = base64ToFile('Скан из предзаявки.jpg', 'data:image/jpeg;base64', res.data.scan)
                    file = fileJS;
                } else {
                    throw new Error(res.data.error.Message);
                }
            } catch (err) {
                const error = (err as any);
                logError(error, `Ошибка при загрузке скана предзаявки`);
            }

            let controlName = '';
            switch (s.fileType) {
                case 12:
                    controlName = 'passport';
                    break;
                case 14:
                    controlName = 'foto';
                    break;
            }
            const control = {...state.uploadFormControls[controlName]};

            if (file) {
                const errors: string[] = [];
                const namesFiles = [];
                const compiled = template(
                    `<ul>
                        <% _.forEach( names, function(name) { %>
                            <li> <%- name %> </li>
                        <% }) %>
                    </ul>`
                );
                control.files = [];
                control.errors = [];
                const errorsMessage = validationFile(file, {
                    rules: control.rules,
                    errorMessage: control.errorMessage,
                });
                if (errorsMessage.length) {
                    errorsMessage.forEach((error) => {
                        if (!~errors.indexOf(error)) {
                            errors.push(error);
                        }
                    });
                }
                namesFiles.push(file.name.replace(extname(file.name), ""));
                control.files.push(file);
                control.descr = compiled({names: namesFiles});
                control.errors = [...errors];
                control.isTouched = true;
                if (controlName === 'foto') {
                    control.captured = null;
                }
            }
            if (control.files && control.files.length) {
                control.isValid = control.errors.length === 0;
                control.isWatchable = true;
            } else {
                control.isValid = false;
                control.isWatchable = false;
            }

            commit(SET_FORM_CONTROLS_FILE, {control, name: controlName});
        })
    },

    async inputFileHandler({commit, state, dispatch}: any, evt: any) {
        const {name, files}  = evt.target;
        let control: any;
        let tempControl: any;

        if (name.startsWith("another")) {
            control = {...state.anotherUploads[name]};
            tempControl = {...state.anotherUploads[name]};
        } else {
            control = {...state.uploadFormControls[name]};
        }

        control.isTouched = true;

        if (files[0]) {
            const errors: string[] = [];
            const namesFiles = [];
            const isMaxFiles = files.length > (control.maxFiles ? control.maxFiles : 1);

            const compiled = template(
                `<ul>
                    <% _.forEach( names, function(name) { %>
                        <li> <%- name %> </li>
                    <% }) %>
                </ul>`
            );

            if (!isMaxFiles) {
                const filesArr = isMaxFiles
                    ? control.maxFiles
                    : files.length;

                control.files = [];
                control.errors = [];

                for (let i = 0; i < filesArr; i++) {
                    const file = files[i];

                    const errorsMessage = validationFile(file, {
                        rules: control.rules,
                        errorMessage: control.errorMessage,
                    });

                    if (errorsMessage.length) {
                        errorsMessage.forEach((error) => {
                            if (!~errors.indexOf(error)) {
                                errors.push(error);
                            }
                        });
                    } else {
                        namesFiles.push(file.name.replace(extname(file.name), ""));
                        control.files.push(file);
                    }
                }

                control.descr = compiled({names: namesFiles});
                control.errors = [...errors];

                if (evt.target.name === 'foto') {
                    control.captured = null;
                }
            } else {
                control.files = [];
                control.descr = "";
                control.errors = [`Максимальное количество файлов ${control.maxFiles}`];
            }
        }
        if (control.files) {
            control.isValid = !control.errors.length;
            control.isWatchable = true;
        } else {
            control.isValid = false;
            control.isWatchable = false;
        }

        if (name.startsWith("another")) {
            commit(SET_ANOTHER_UPLOAD, {control, name});
            const file = control.files[0];
            try {
                await dispatch("requestAnotherUpload", {file, name});
            } catch(err) {
                commit(SET_ANOTHER_UPLOAD, {name, control: tempControl});
            }
        } else {
            commit(SET_FORM_CONTROLS_FILE, {control, name});
        }
    },

    async getReferenceCountries({commit}) {
        try {
            const res = await fetch.post(`api/FilialClient/getCountries`);

            if (res?.status !== 200 || !res?.data?.countries || !Array.isArray(res.data.countries)) {
                throw new Error('запрос завершился неуспешно');
            }

            commit(SET_OPTIONS_COUNTRY, res.data.countries);
        } catch (err) {
            const error = err as any;
            const errorMessage = JSON.stringify(
                error.response?.data ||
                error.message ||
                'Неизвестная ошибка'
            );
            logError(error, `Ошибка получения списка стран`);
            Vue.$toast.error(`Ошибка получения списка стран: ${errorMessage}`, TOAST_OPTIONS.Error);
        }
    },

    async getReferenceRegions({commit}) {
        try {
            const res = await fetch.post(`api/FilialClient/getRegions`);

            if (res?.status !== 200 || !res?.data?.regions || !Array.isArray(res.data.regions)) {
                throw new Error('запрос завершился неуспешно');
            }

            commit(SET_OPTIONS_REGION, [{value: 0, label: 'Не выбрано'}, ...res.data.regions]);
        } catch (err) {
            const error = err as any;
            const errorMessage = JSON.stringify(
                error.response?.data ||
                error.message ||
                'Неизвестная ошибка'
            );
            logError(error.message, `Ошибка получения списка регионов`);
            Vue.$toast.error(`Ошибка получения списка регионов: ${errorMessage}`, TOAST_OPTIONS.Error);
        }
    },

    async getReferenceIdentificationDocumentTypes({commit}){
        try{
            const res = await fetch.get(`api/FilialClient/getIdentificationDocumentTypes`);
            if (res.status === 200) {
                commit(SET_OPTIONS_IDENTIFICATION_DOCUMENT_TYPES, res.data.identificationDocumentTypes);
            }
            
        }catch(err){
            const error = (err as any);
            logError(error, `Ошибка получения списка типов документов`);
            throw error;
        }
    },

    async downloadHandler({commit}, docInfo: {}) {
        try {
            const res = await fetch.post(`api/filialClient/download`, docInfo);

            if (res.status === 200 && !res.data.error) {
                const {document, documentMimeType, documentName} = res.data;

                download(document, documentName, documentMimeType);
            } else {
                logErrorText(`Ошибка загрузки файла: ${res.data.error}`);

                (app as any).$modal.show("dialog", {
                    title: "Ошибка",
                    text: res.data.error,
                    buttons: [
                        {
                            title: "ПРОДОЛЖИТЬ",
                            default: true,
                            handler: () => {
                                (app as any).$modal.hide("dialog");
                            },
                        },
                    ],
                });
            }
        } catch (err) {
            const error = (err as any);
            logError(error, `Ошибка загрузки файла`);

            throw error;
        }
    },

    async downloadRaw({commit}, docInfo: {}) {
        try {
            const res = await fetch.post(`api/filialClient/downloadRaw`, docInfo);
            if (res.status === 200 && !res.data.error) {
                return res.data.file;
            } else {
                logErrorText(`Ошибка загрузки файла: ${res.data.error}`);
                (app as any).$modal.show("dialog", {
                    title: "Ошибка",
                    text: res.data.error,
                    buttons: [
                        {
                            title: "ПРОДОЛЖИТЬ",
                            default: true,
                            handler: () => {
                                (app as any).$modal.hide("dialog");
                            },
                        },
                    ],
                });
            }
        } catch (err) {
            const error = (err as any);
            logError(error, `Ошибка загрузки сырого файла`);

            throw error;
        }
    },

    async getCertificates({state}) {
        const appData = new CertificateCheckInfo();
        appData._SN = state.formControls.surname.value;
        appData._G = (state.formControls.name.value+" "+state.formControls.patronymic.value).trim();
        appData._SNILS = state.formControls.snils.value;
        appData._INN = state.formControls.personInn.value !== "" ? state.formControls.personInn.value : state.formControls.inn.value;
        appData._OGRN = state.formControls.ogrn.value;
        appData._OGRNIP = state.formControls.ogrnip.value;
        appData._applType = state.formControls.typeEntre.value === "fl" ? 1 :
         (state.formControls.typeEntre.value === "ip" ? 2:3); 
        appData._fns = state.isFNSIssueAvailable!==null ? state.isFNSIssueAvailable : false;
        //crypto.certs = [];
        try {
            if(appData._fns && fnsOGRN)
                await crypto.getCertificates({filter: fnsOGRN},appData);
            else
                await crypto.getCertificates(/*{filter: ANALIT_CENTR_OGRN}*/{filter: ""},appData);
        } catch (err) {
            const error = (err as any);
            logError(error, "Ошибка чтения сертификатов");
        }

        return await crypto.certs;
    },

    async install({commit}, certificate: string) : Promise<string | undefined> {
        return await crypto.install(certificate);
    },

    async fetchUpload({state, commit}, payload: object) {
        const body = new FormData();

        body.append("orderId", String(state.orderId));

        if (isObject(payload)) {
            for (const [key, value] of Object.entries(payload)) {
                if (isString(value) || value instanceof Blob) {
                    body.append(key, value);
                } else if(isBoolean(value)){
                    body.append(key,value.toString());
                }
            }
        }

        try {
            commit(SET_IS_UPLOAD_FORM_LOADED, false);

            const {data}: AxiosResponse<IDocumentsResponse> = await fetch.post(
                "api/filialClient/upload",
                body
            );

            const {certificateRequested, hash, documentsCollected, statusId} = data;

            if (data.hasOwnProperty("error") && data.error) {
                if(data.hasOwnProperty("existingCertsInFNS") && data.existingCertsInFNS){
                    (app as any).modalErrorClose = false;
                    commit(SET_CHECK_CERT_IN_FNS_MESSAGE, data.error);
                    (app as any).$modal.show("existingInFNS");    
                } else {
                    throw data.error;
                }
            }

            commit(SET_IS_EDIT_UPLOAD, false);
            commit(SET_IS_DOCUMENTS_COLLECTED, documentsCollected);
            commit(SET_IS_CERTIFICATE_REQUESTED, certificateRequested);
            commit(SET_STATUS_ID, statusId);

            return data;
        } catch (error) {
            let mess = `Ошибка при подписи документов ${state.orderId}`;

            if (error && !isEmpty(error)) {
                mess += `\n` + error;
            }
            Vue.$toast.error(mess, {
                position: POSITION.TOP_LEFT,
                timeout: 5000,
                closeOnClick: false,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
        } finally {
            commit(SET_IS_UPLOAD_FORM_LOADED, true);
        }
    },

    /**
     * Saving Documents on the server.
     */
    async getFileHash({commit, state, dispatch}: any, addBody?: object){
        const body = new FormData();

        body.append("orderId", String(state.orderId));

        if (isObject(addBody)) {
            for (const [key, value] of Object.entries(addBody)) {
                if (isString(value) || value instanceof Blob) {
                    body.append(key, value);
                } else if(isBoolean(value)){
                    body.append(key,value.toString());
                }
            }
        }

        try {
            commit(SET_IS_UPLOAD_FORM_LOADED, false);

            const {data}: AxiosResponse<IDocumentsResponse> = await fetch.post(
                "api/filialClient/upload",
                body
            );

            const {certificateRequested, hash, documentsCollected, statusId} = data;

            if (data.hasOwnProperty("error") && data.error) {
                if(data.hasOwnProperty("existingCertsInFNS") && data.existingCertsInFNS){
                    (app as any).modalErrorClose = false;
                    commit(SET_CHECK_CERT_IN_FNS_MESSAGE, data.error);
                    (app as any).$modal.show("existingInFNS");    
                } else {
                    throw data.error;
                }
            }

            commit(SET_IS_EDIT_UPLOAD, false);
            //commit(SET_IS_DOCUMENTS_COLLECTED, documentsCollected);
            //commit(SET_IS_CERTIFICATE_REQUESTED, certificateRequested);
            //commit(SET_STATUS_ID, statusId);

            return data;
        } catch (error) {
            let mess = `Ошибка при подписи документов ${state.orderId}`;

            if (error && !isEmpty(error)) {
                mess += `\n` + error;
            }
            Vue.$toast.error(mess, {
                position: POSITION.TOP_LEFT,
                timeout: 5000,
                closeOnClick: false,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
        } finally {
            commit(SET_IS_UPLOAD_FORM_LOADED, true);
        }  
    },
    async handlerSaveDocuments({commit, state, dispatch}: any, addBody?: object) {
        const imageTypeCompressor = ["image/png", "image/jpeg"];
        const imageSizePromise: Array<Promise<{ name: string; blob: Blob }>> = [];
        const zip = new JSZip();

        Object.keys(state.uploadFormControls).forEach((nameControl) => {
            const control = {...state.uploadFormControls[nameControl]};

            if (control.files) {
                control.files.forEach((file: File, i: number) => {
                    if (imageTypeCompressor.includes(file.type) && nameControl!=="application") {
                        imageSizePromise.push(compressorAsync(file));
                    }
                });
                // } else if(control.captured){
                //   try{
                //     const fotoBlob=await fetch(control.captured).then(res => res.blob());
                //     imageSizePromise.push(compressorBlobAsync(fotoBlob,'foto.jpg'));
                //   }catch(error) {
                //     throw error;
                //   }
            }
        });

        const fotoControl = {...state.uploadFormControls['foto']};
        var fotoBlob = null;
        if (fotoControl.captured) {
            try {
                // const fotoBlob=await fetch(fotoControl.captured).then(res => res.blob());
                fotoBlob = await base64toBlob(fotoControl.captured, 'image/jpeg');
                imageSizePromise.push(compressorBlobAsync(fotoBlob, 'foto.jpg'));
            } catch (error) {
                throw error;
            }
        }

        try {
            const sizeImageFile = await Promise.all(imageSizePromise);
            const controlApp = {...state.uploadFormControls["application"]};
            let appFileExt = "";
            if(controlApp.files){
                appFileExt = extname(controlApp.files[0].name);
            }
            Object.keys(state.uploadFormControls).forEach((nameControl) => {
                const control = {...state.uploadFormControls[nameControl]};
                if (control.files) {
                    control.files.forEach((file: File, i: number) => {
                        let imageFile: Blob | undefined;

                        if (imageTypeCompressor.includes(file.type)&& nameControl!=="application") {
                            imageFile = sizeImageFile.find(({name}) => file.name === name)
                                ?.blob;
                        }

                        if (control.files.length > 1) {
                            const folder = zip.folder(control.title);

                            if (folder) {
                                folder.file(
                                    control.title + (i + 1) + extname(file.name),
                                    imageFile || file
                                );
                            }
                        } else {
                            if(nameControl === "applicationSignature") {
                                const nameArr = file.name.trim().split(".");
                                zip.file("Подпись заявления" + appFileExt + "." +nameArr[nameArr.length-1], imageFile || file);
                            } else {
                                zip.file(control.title + extname(file.name), imageFile || file);
                            }
                        }
                    });
                }
            });
            if (fotoBlob != null) {
                let imageFile: Blob | undefined;
                imageFile = sizeImageFile.find(({name}) => 'foto.jpg' === name)?.blob;
                zip.file(fotoControl.title + '.jpg', imageFile || new Blob());
            }
        } catch (error) {
            throw error;
        }

        try {
            const zipGen = await zip.generateAsync({type: "blob"});

            const promise = dispatch(
                "fetchUpload",
                Object.assign(
                    {
                        zip: zipGen,
                        typeId: String(InitialFileTypes.DocumentsZip),
                    },
                    addBody
                )
            );

            promise.then((data: any) => {
                if (data.result) {
                    Object.keys(state.uploadFormControls).forEach((name) => {
                        const control = {...state.uploadFormControls[name]};
                        if (control.files) {
                            control.isCollected = true;
                            commit(SET_FORM_CONTROLS_FILE, {control, name});
                        }
                    })
                }
            })

            return promise;
        } catch (error) {
            throw error;
        }
    },

    /**
     * Sign documents and save them on the server.
     */
    async saveAndSignDocuments({state, commit, dispatch, getters, rootGetters}: any) {
        (app as any).$modal.hide("confirm");
        if (app) {
            (app as any).$modal.show("loader");
        }
        if (state.currentCertThumbprint) {
            const formBody: { [key: string]: string | Blob } = {};
            const сertificate = crypto.certs.find(
                (x) => x.value === state.currentCertThumbprint
            );

            if (сertificate === undefined) {
                throw new Error("Не выбран сертификат.");
            }

            formBody.hashAlg = сertificate.alg.toString();
            const hashAlg = сertificate.alg;
            
            try {
                commit(SET_IS_UPLOAD_FORM_LOADED, false);

                formBody.requestApplicationHash = "requestApplicationHash";
                formBody.typeId = "-1";
                const {hash} = await dispatch(
                    "getFileHash",
                    formBody
                );

                if (await !dispatch("validateCert")) {
                    return;
                }

                const signedHash = await crypto.signHash(
                    hash,
                    state.currentCertThumbprint
                );

                if (!signedHash || isEmpty(signedHash)) {
                    commit(
                        SET_ERROR_MESSAGE,
                        "Не удалось подписать заявление. Попробуйте ещё раз или обратитесь в поддержку!"
                    );
                } else {
                    let foundRevoke = false;
                    const revokes = state.filesDownload.map((file: any) => {
                        for (const doc of DOWNLOAD_DOCUMENTS) {
                            if (doc.typeId === file.typeId && file.typeId === InitialFileTypes.Revocation) {
                                const { descr, ...download } = doc;
                                foundRevoke = true;
                                return {
                                    ...download,
                                    ...file,
                                    descr: descr
                                        .replace("На сертификат ",download.title+"_")
                                        .replace("%%", file.orderId)
                                        .replace("%1%", file.fileMarker)
                                };
                            }
                        }
                    });
                    if(foundRevoke){
                        const zip = new JSZip();
                        for(const revokeItem of revokes){
                            if(revokeItem){
                                const fileContent = await dispatch("downloadRaw",revokeItem);
                                if(fileContent){
                                    const byteCharacters = Buffer.from(fileContent,"base64").toString('latin1');
                                    const byteArrays = [];

                                    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                                        const slice = byteCharacters.slice(offset, offset + 512);

                                        const byteNumbers = new Array(slice.length);
                                        for (let i = 0; i < slice.length; i++) {
                                        byteNumbers[i] = slice.charCodeAt(i);
                                        }

                                        const byteArray = new Uint8Array(byteNumbers);
                                        byteArrays.push(byteArray);
                                    }

                                    const blob = new Blob(byteArrays, {type: ''});
                                    zip.file(revokeItem.descr+".pdf",blob)
                                }
                            }
                        }
                        const zipGen64 = await zip.generateAsync({type: "base64"});
                        
                        const revokesHash = await crypto.calcHash(zipGen64, hashAlg);
                        
                        const zbyteCharacters = Buffer.from(zipGen64,"base64").toString('latin1');
                        const zbyteArrays = [];

                        for (let zoffset = 0; zoffset < zbyteCharacters.length; zoffset += 512) {
                            const zslice = zbyteCharacters.slice(zoffset, zoffset + 512);

                            const zbyteNumbers = new Array(zslice.length);
                            for (let i = 0; i < zslice.length; i++) {
                                zbyteNumbers[i] = zslice.charCodeAt(i);
                            }

                            const zbyteArray = new Uint8Array(zbyteNumbers);
                            zbyteArrays.push(zbyteArray);
                        }

                        const zipGen = new Blob(zbyteArrays, {type: ''});
                        
                        if (!revokesHash || isEmpty(revokesHash)) {
                            commit(
                                SET_ERROR_MESSAGE,
                                "Не удалось подписать заявление на отзыв. Попробуйте ещё раз или обратитесь в поддержку!"
                            );
                        } else {
                            const revokesSignedHash = await crypto.signHash(
                                revokesHash,
                                state.currentCertThumbprint
                            );
    
                            if (!revokesSignedHash || isEmpty(revokesSignedHash)) {
                                commit(
                                    SET_ERROR_MESSAGE,
                                    "Не удалось подписать заявление на отзыв. Попробуйте ещё раз или обратитесь в поддержку!"
                                );
                            } else {
                                const {newStatusId} = await dispatch("fetchUpload", {
                                    signedHash: new Blob([signedHash], {type: "html/text"}),
                                    revokesZip: zipGen, 
                                    revokesSignedHash: new Blob([revokesSignedHash], {type: "html/text"}),
                                    typeId: String(InitialFileTypes.FilialClientSignatures),
                                });
    
                                commit(SET_IS_EDIT_UPLOAD, false);
                                commit(SET_IS_DOCUMENTS_COLLECTED, true);
                                await up(commit.bind(null, NEXT_ACTIVE_TAB));
                            }
                        }
                        
                    } else {
                        const {documentsCollected,newStatusId} = await dispatch("fetchUpload", {
                            signedHash: new Blob([signedHash], {type: "html/text"}),
                            typeId: String(InitialFileTypes.FilialClientSignatures),
                        });
                        commit(SET_IS_EDIT_UPLOAD, false);
                        commit(SET_IS_DOCUMENTS_COLLECTED, documentsCollected);
                        await up(commit.bind(null, NEXT_ACTIVE_TAB));
                    }
                }
            } catch (error) {
                throw error;

            } finally {
                commit(SET_IS_UPLOAD_FORM_LOADED, true);
                if (app) {
                    (app as any).$modal.hide("loader");
                }
            }
        }
    },

    async signPrintForm({state, commit, dispatch, getters, rootGetters}: any) {
        (app as any).$modal.hide("confirm");
        if (app) {
            (app as any).$modal.show("loader");
        }
        if (state.currentCertThumbprint) {
            const сertificate = crypto.certs.find(
                (x) => x.value === state.currentCertThumbprint
            );

            if (сertificate === undefined) {
                throw new Error("Не выбран сертификат.");
            }

            const hashAlg = сertificate.alg;
            
            try {
                //commit(SET_IS_UPLOAD_FORM_LOADED, false);

                if (await !dispatch("validateCert")) {
                    return;
                }

                const pfContent = await dispatch("downloadRaw",state.certificate[1]);
                if(pfContent){
                    const certControl = {...state.uploadFormControls.certificateSigned};
                    const securityInfoControl = {...state.uploadFormControls.securityInformationSigned};
                    const printingFormSignatureControl = {...state.uploadFormControls.printingFormSignature};
            
                    const pfHash = await crypto.calcHash(pfContent, hashAlg);
                    if (!pfHash || isEmpty(pfHash)) {
                        Vue.$toast.error("Не удалось подписать печатную форму. Попробуйте ещё раз или обратитесь в поддержку!", TOAST_OPTIONS.Error);
                    } else {
                        const pfSignedHash = await crypto.signHash(
                            pfHash,
                            state.currentCertThumbprint
                        );
    
                        if (!pfSignedHash || isEmpty(pfSignedHash)) {
                            Vue.$toast.error("Не удалось подписать печатную форму. Попробуйте ещё раз или обратитесь в поддержку!", TOAST_OPTIONS.Error);
                        } else {
                            const files: any[] = [];
                            files.push({fileContent: `base64,${pfSignedHash}`, fileType: InitialFileTypes.PrintingFormSignature});
                            const initialApplicationId = Number(state.orderId);
                            const fileId = null || certControl.fileId || securityInfoControl.fileId || printingFormSignatureControl.fileId;
                            const body = {initialApplicationId, fileId, files};
                            const res = await fetch.post(`/api/filialClient/uploadApplicantSignedDocuments`, body);
                            if (res.status === 200 && res.data && res.data.success) {
                                commit(SET_FORM_CONTROLS_FILE, {control: {...certControl, fileId: res.data.fileId}, name: "certificateSigned"});
                                commit(SET_FORM_CONTROLS_FILE, {control: {...securityInfoControl, fileId: res.data.fileId}, name: "securityInformationSigned"});
                                commit(SET_FORM_CONTROLS_FILE, {control: {...printingFormSignatureControl, fileId: res.data.fileId}, name: "printingFormSignature"});
                                const message = `Документы успешно загружены`
                                Vue.$toast.success(message, TOAST_OPTIONS.Success);
                            } else {
                                const message = res.data && res.data.error ?
                                    `Ошибка при загрузке документов. ${res.data.error}` :
                                    `Неизвестная ошибка при загрузке документов`;
                                Vue.$toast.error(message, TOAST_OPTIONS.Error);
                            }
                        }
                    }
                } else {
                    Vue.$toast.error("Не удалось подписать печатную форму. Попробуйте ещё раз или обратитесь в поддержку!", TOAST_OPTIONS.Error);    
                }           
            } catch (error) {
                throw error;
            } finally {
                if (app) {
                    (app as any).$modal.hide("loader");
                }
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                dispatch("fetchApplication",urlParams.get('app'));
            }
        }
    },

    /*
     * Подписывает запрос на сетрификат при помощи CryptoProWrapper
     */
    async signCertRequest({state, commit}) {
        if (app) {
            (app as any).$modal.show("loader");
        }
        try {
            if (state.currentCertThumbprint) {
                const сertificate = crypto.certs.find(
                    (x) => x.value === state.currentCertThumbprint
                );

                if (сertificate === undefined) {
                    throw new Error("Не выбран сертификат.");
                }

                const dataAsBase64 = state.newCertRequest
                    .replace(/^.+base64,/, "")
                    .replace("-----BEGIN NEW CERTIFICATE REQUEST-----", "")
                    .replace("-----END NEW CERTIFICATE REQUEST-----", "")
                    .replace("\n","")
                    .replace("\r", "")
                    .trim();
                const certThumbprint = state.currentCertThumbprint;
                const detached = false;
                const signedRequest = await crypto.signRequest(dataAsBase64, certThumbprint, detached);
                (app as any).$modal.hide("confirm");
                commit(CLEAR_NEW_CERT_REQUEST);
                commit(SET_SIGNED_CERT_REQUEST, signedRequest);
            }
        } catch (err) {
            const error = (err as any);
            let message = `Не удалось подписать файл запроса. ` +  
                error.data && error.data.message ? error.data.message : "Неизвестная ошибка";
            Vue.$toast.error(message, TOAST_OPTIONS.Error);
        } finally {
            if (app) {
                (app as any).$modal.hide("loader");
            }
        }
    },

    /*
     * Загружает подписанный запрос на сертифкат на сервер
     */
    async uploadSignedCertRequest({state, commit}: any, orderId?: number | string) {
        if (app) {
            (app as any).$modal.show("loader");
        }
        try {
            const signedRequest = state.signedCertRequest;
            commit(CLEAR_SIGNED_CERT_REQUEST);

            if (!signedRequest || isEmpty(signedRequest)) {
                commit(SET_ERROR_MESSAGE, "Файл запроса не подписан. Попробуйте ещё раз или обратитесь в поддержку!");
            } else {
                const crypto = new Blob([signedRequest], {type: "html/text"});
                // const filename = `${state.formControls.surname.value}_request_${state.orderId}.sgn`
                // const file = new File([crypto], filename, {type: crypto.type})
                // download(file, decodeURI(filename), "html/text");
                const body = new FormData()
                body.append("crypto", crypto);
                body.append("orderId", orderId || state.orderId);
                body.append("typeId", InitialFileTypes.CertificateRequestSigned.toString());

                const res = await fetch.post(`api/file/upload`, body);
                if(!res.data || !!res.data.error){
                    throw new Error(`${res.data && res.data.error ? res.data.error : "Неизвестная ошибка"}`);
                }
                if (res.status === 200) {
                    commit(SET_IS_CERTIFICATE_REQUESTED, res.data.result);
                    commit(SET_STATUS_ID, res.data.statusId);
                    Vue.$toast.success("Файл запроса успешно подписан и отправлен", TOAST_OPTIONS.Success);
                } else {
                    throw new Error("Неизвестная ошибка");
                }
            }
        } catch (err) {
            const error = (err as any);
            let message = `Не удалось подписать и отправить файл запроса для заявки ${state.orderId}. ` +  
                error.data && error.data.message ? error.data.message : "Неизвестная ошибка";
            Vue.$toast.error(message, TOAST_OPTIONS.Error);
        } finally {
            if (app) {
                (app as any).$modal.hide("loader");
            }
        }
    },

    /*
     * Подписывает файл отзыва при помощи CryptoProWrapper
     */
    async signCertRevoke({state, commit}) {
        if (app) {
            (app as any).$modal.show("loader");
        }
        try {
            if (state.currentCertThumbprint) {
                const сertificate = crypto.certs.find(
                    (x) => x.value === state.currentCertThumbprint
                );

                if (сertificate === undefined) {
                    throw new Error("Не выбран сертификат.");
                }

                const dataAsBase64 = state.newCertRevoke.replace(/^.+base64,/, "");

                const certThumbprint = state.currentCertThumbprint;
                const detached = false;
                const signedRevoke = await crypto.signRequest(dataAsBase64, certThumbprint, detached);
                (app as any).$modal.hide("confirm");
                commit(CLEAR_NEW_CERT_REVOKE);
                commit(SET_SIGNED_CERT_REVOKE, signedRevoke);
            }
        } catch (err) {
            const error = (err as any);
            let message = `Не удалось подписать файл отзыва. ` +  
                error.data && error.data.message ? error.data.message : "Неизвестная ошибка";
            Vue.$toast.error(message, TOAST_OPTIONS.Error);
        } finally {
            if (app) {
                (app as any).$modal.hide("loader");
            }
        }
    },

    /*
     * Загружает подписанный отзыв на сервер
     */
    async uploadSignedCertRevoke({state, commit}: any) {
        if (app) {
            (app as any).$modal.show("loader");
        }
        try {
            const signedRevoke = state.signedCertRevoke;
            commit(CLEAR_SIGNED_CERT_REVOKE);

            if (!signedRevoke || isEmpty(signedRevoke)) {
                commit(SET_ERROR_MESSAGE, "Файл отзыва не подписан. Попробуйте ещё раз или обратитесь в поддержку!");
            } else {
                const crypto = new Blob([signedRevoke], {type: "html/text"});
                // const filename = `${state.formControls.surname.value}_revoke_${state.orderId}.sgn`
                // const file = new File([crypto], filename, {type: crypto.type})
                // download(file, decodeURI(filename), "html/text");
                const body = new FormData()
                body.append("crypto", crypto);
                body.append("orderId", state.orderId);
                body.append("typeId", `${InitialFileTypes.CertificateRevokeSigned}`);

                const res = await fetch.post(`api/file/upload`, body);
                if(!res.data || !!res.data.error){
                    throw new Error(`${res.data && res.data.error ? res.data.error : "Неизвестная ошибка"}`);
                }
                if (res.status === 200) {
                    Vue.$toast.success("Файл отзыва успешно подписан и отправлен", TOAST_OPTIONS.Success);
                } else {
                    throw new Error("Неизвестная ошибка");
                }
            }
        } catch (err) {
            const error = (err as any);
            let message = `Не удалось подписать и отправить файл отзыва для заявки ${state.orderId}. ` +  
                error.data && error.data.message ? error.data.message : "Неизвестная ошибка";
            Vue.$toast.error(message, TOAST_OPTIONS.Error);
        } finally {
            if (app) {
                (app as any).$modal.hide("loader");
            }
        }
    },

    /**
     * Save documents on the server and sign them.
     */
    async handlerHeadSign({state, commit, dispatch}: any) {
        if (state.currentCertThumbprint) {
            const formBody: { [key: string]: string | Blob } = {};
            const сertificate = crypto.certs.find(
                (x) => x.value === state.currentCertThumbprint
            );

            if (сertificate === undefined) {
                throw new Error("Не выбран сертификат.");
            }

            formBody.hashAlg = сertificate.alg.toString();
            formBody.requestZipHash = "requestZipHash";
            formBody.typeId = "-1";

            try {
                commit(SET_IS_UPLOAD_FORM_LOADED, false);

                const {hash} = await dispatch("fetchUpload", formBody);

                if (await !dispatch("validateCert")) {
                    return;
                }

                const signedHash = await crypto.signHash(
                    hash,
                    state.currentCertThumbprint
                );

                if (!signedHash || isEmpty(signedHash)) {
                    commit(
                        SET_ERROR_MESSAGE,
                        "Не удалось подписать заявление. Попробуйте ещё раз или обратитесь в поддержку!"
                    );
                } else {
                    await dispatch("fetchUpload", {
                        signedHash: new Blob([signedHash], {type: "html/text"}),
                        typeId: String(InitialFileTypes.DocumentsZipHeadSig),
                    });

                    commit(SET_IS_EDIT_UPLOAD, false);
                    commit(SET_IS_DOCUMENTS_COLLECTED, true);
                    (app as any).$modal.hide("confirm");
                    await up(commit.bind(null, NEXT_ACTIVE_TAB));
                }
            } catch (error) {
                let mess = `Ошибка при подписи документов ${state.orderId}`;

                if (error && !isEmpty(error)) {
                    mess += `\n` + error;
                }
                Vue.$toast.error(mess, {
                    position: POSITION.TOP_LEFT,
                    timeout: 5000,
                    closeOnClick: false,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: false,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
            } finally {
                commit(SET_IS_UPLOAD_FORM_LOADED, true);
            }
        }
    },

    async validateCert({state, commit}): Promise<boolean> {
        if (!state.currentCertThumbprint) {
            return false;
        }

        try {
            const exportedCert = await crypto.exportCertificate(
                state.currentCertThumbprint
            );

            const {data} = await fetch.post(
                "api/certificate/check",
                JSON.stringify(exportedCert),
                {headers: {"Content-Type": "application/json"}}
            );

            if (!data.result && data.hasOwnProperty("error")) {
                throw data.error;
            }

            return data.result;
        } catch (err) {
            const error = (err as any);
            let mess = "Ошибка валидации сертификата!";

            if (error.data && error.data.message) {
                mess = error.data.message;
            }

            commit(SET_IS_UPLOAD_FORM_LOADED, true);
            commit(SET_IS_CERTIFICATE_REQUESTED, false);
            commit(SET_ERROR_MESSAGE, mess);

            logError(error, "Ошибка валидации сертификата");

            return false;
        }
    },

    async fetchApplication({commit, dispatch, state}: any, link: string) {
        !!app && (app as any).$modal.show("loader");
        try {
            const body: any = {
                token: String(link)
            };
            const res = await fetch.post(`api/FilialClient/view`,body);

            if (res.status === 200) {
                const {
                    error,
                    orderId,
                    application,
                    status,
                    statusId,
                    managerId,
                    creationDate,
                    changeDate,
                    files,
                    comment,
                    managerComment,
                    documentsCollected,
                    certificateRequested,
                    readonlyKeys,
                    certificate,
                    externalSourcePkcs10,
                    archived,
                    updStatus,
                    updStatusId,
                    updocument,
                    isSendUPDtoEDM,
                    hideUtd,
                    isManagerCommentEditable,
                    isKKMReceiptLaunchable,
                    isFNSIssueAvailable,
                    scans,
                    needToAttachRevoke,
                    smevChecks,
                    edmTypes,
                    uploads,
                    products,
                    sigCheckStatus,
                    sigIssuerCN,
                    checkCertInFNSResult,
                    payer,
                    filialClientLink,
                    filialInfo,
                } = res.data;

                commit(SET_IS_EDIT, false);
                if(error){
                    commit(SET_IS_LINK_ERROR, true);
                    Vue.$toast.error(error, {
                        position: POSITION.TOP_LEFT,
                        timeout: 5000,
                        closeOnClick: false,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: false,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false
                    });
                } else {
                    commit(SET_IS_LINK_ERROR, false);
                    if (scans && scans.length) {
                        commit(SET_SCANS_FROM_PREAPP, scans);
                    }

                    dispatch("initProducts", {products, productsSelected: application.products}, {root: true});

                    if (readonlyKeys) {
                        commit(SET_READONLY_FIELDS_KEY_ALL, readonlyKeys);
                    }

                    dispatch("initFields", {application, payer: payer || {}});

                    commit(SET_IS_DOCUMENTS_COLLECTED, (application.filialClientFlags & 0x0001)===0x0001);
                    
                    dispatch("initUploads", {uploads, scans});
                    commit(SET_CERTIFICATE, certificate);
                    commit(SET_IS_CERTIFICATE_REQUESTED, (application.filialClientFlags & 0x0002)===0x0002);

                    if (files.length) {
                        commit(SET_FILES_DOWNLOAD, files);
                        commit(SET_DOCUMENT_DOWNLOAD, true);
                    }
                    
                    commit(SET_IS_FORM_VALID, true);
                    commit(SET_IS_EDIT_UPLOAD, !documentsCollected);
                    if (documentsCollected && statusId !== STATUSES.DocumentsSending.id) {
                        commit(SET_IS_DOCUMENTS_COLLECTED, documentsCollected);
                    }
                    commit(SET_ORDER_ID, orderId);
                    console.log(`orderId ${orderId}`)
                    dispatch("initStatus", {status, statusId});
                    commit(SET_MANAGER_ID, managerId);
                    commit(SET_ARCHIVED, archived);
                    commit(SET_EXTERNAL_SOURCE_PKCS_10, externalSourcePkcs10);
                    commit(SET_COMMENT, comment);
                    commit(SET_MANAGERCOMMENT, managerComment);
                    commit(SET_IS_MANAGERCOMMENT_EDITABLE, isManagerCommentEditable);
                    commit(SET_CREATION_DATE, creationDate);
                    commit(SET_CHANGE_DATE, changeDate);
                    commit(SET_IS_SUBMITED, true);
                    commit(SET_PAYMENT_STATUS_ID, application.paymentStatus);
                    commit(SET_PAYMENT_STATUS_DESCRIPTION, application.paymentStatusDescription);
                    commit(SET_LAST_PAYMENT_DATE, application.lastPaymentDate);
                    commit(SET_UPD_REQUESTED, updStatusId > 0);
                    commit(SET_UPD_STATUS, updStatus);
                    commit(SET_UPD_STATUS_ID, updStatusId);
                    commit(SET_SEND_UPD_TO_EDM, isSendUPDtoEDM);
                    commit(SET_HIDE_UTD, hideUtd);
                    commit(SET_UPD_DOCUMENT, updocument);
                    commit(SET_IS_KKMRECEIPT_LAUNCHABLE, isKKMReceiptLaunchable);
                    commit(SET_IS_FNS_ISSUE_AVAILABLE, isFNSIssueAvailable);
                    commit(SET_NEED_TO_ATTACH_REVOKE,needToAttachRevoke);
                    commit(SET_SMEV_CHECKS, smevChecks);
                    commit(SET_EDM_TYPES, edmTypes);
                    commit(SET_FNS_EXISTING_CERTS, application.existingCertsInFNS || []);
                    commit(SET_SIG_CHECK_STATUS, sigCheckStatus);
                    commit(SET_SIG_ISSUER_CN, sigIssuerCN);
                    commit(SET_FILIAL_CLIENT_LINK,filialClientLink);
                    commit(SET_IS_PDN_USING_PROHIBITED, application.isPdnUsingProhibited);
                    commit(SET_FILIAL_INFO, filialInfo);
                    dispatch("checkIsDirector");
                    dispatch("definePhotoRequired");
                    dispatch("definePassportRequired");
                    // dispatch("defineProxyRequired"); доверенность стала необязательной во всех случаях
                    dispatch("defineFnsCodeActive");
                    dispatch("getApplicantSignedDocsListFromArchive");
                    up();
                }
            }
        } catch (err) {
            const error = (err as any);
            if (error.response && error.response.status === 403) {
                router.push("/403");
            } else {
                throw err;
            }
        } finally {
            !!app && (app as any).$modal.hide("loader");
        }
    },
    async submitHandler({commit, state, dispatch, getters}: any) {
        if (getters.getIsFormValid) {

            const isExtraPayer = state.formControls["payerSubjectType"].value !== PAYER_TYPES.fl.value;
            
            const body: any = {
                orderId: Number(state.orderId),
                products: await dispatch("getProductsSelected", null, {root: true}),
                cert: (cert.state as CertState).cert,
                readonlyKeys: state.fieldsReadonlyKeys,
                payer: isExtraPayer ? {} : null
            };

            Object.keys(state.formControls).forEach((nameControl) => {
                const control = {...state.formControls[nameControl]};
                const val = control.value;
                if (nameControl === "typeEntre") {
                    body[nameControl] =
                        (val === "fl" && 1) || (val === "ip" && 2) || (val === "ur" && 3);
                } else if (control.active) {
                    if(nameControl.startsWith("identificationKind")){
                        body[nameControl] = control.value-1;
                    } else if(nameControl.startsWith("identificationDocumentTypeId")){
                        body["identificationDocumentType"] = control.value;
                    } else if (nameControl.startsWith("payer")) {
                        if (isExtraPayer) {
                            const name = getPayerFieldName(nameControl);
                            if (nameControl == "payerSubjectType" && val !== PAYER_TYPES.fl.value) {
                                body.payer = {};
                                const payerSubjectType = Object.values(PAYER_TYPES).find(type => type.value === val);
                                payerSubjectType && (body.payer[name] = payerSubjectType.id);
                            } else {
                                body.payer[name] = typeof val === "string" ? val.trim() : val;
                            }
                        }
                    } else if (nameControl.startsWith('region')) {
                        body[nameControl] = typeof val === "number" ? val : undefined;
                    } else {
                        body[nameControl] = typeof val === "string" ? val.trim() : val;
                    }
                }
            });

            try {
                commit(SET_IS_SUBMITED, true);
                const res = await fetch.post(`api/InitialApplication`, body);

                if (res.status === 200) {
                    if (!res.data.hasOwnProperty("error")) {
                        if (!state.orderId) {
                            const {orderId} = res.data;
                            dispatch("uploadSignedCertRequest", orderId);
                            router.push(`/application/${orderId}`, () => {window.location.reload(); });
                        } else {
                            window.location.reload(); 
                        } 
                    } else {
                        commit(SET_IS_SUBMITED, false);
                        let mess = `Ошибка при сохранении заявки ${state.orderId}: ${res.data.error}`;

                        Vue.$toast.error(mess,
                            {
                                position: POSITION.TOP_LEFT,
                                timeout: 7500,
                                closeOnClick: false,
                                pauseOnFocusLoss: true,
                                pauseOnHover: true,
                                draggable: false,
                                draggablePercent: 0.6,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: "button",
                                icon: true,
                                rtl: false
                            });
                    }
                }
            } catch (err) {
                const error = (err as any);
                commit(SET_IS_SUBMITED, false);
                logError(error, `Ошибка при сохранении заявки ${state.orderId}`);
                let mess = `Ошибка при сохранении заявки ${state.orderId}`;

                if (error.data && error.data.message) {
                    mess = error.data.message;
                }
                Vue.$toast.error(mess, {
                    position: POSITION.TOP_LEFT,
                    timeout: 5000,
                    closeOnClick: false,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: false,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
            }
        } else {
            dispatch('setActiveTab');
            scrollToInvalidFormItem();
        }
    },

    async genRequestFile({commit, state, dispatch, rootGetters}: any) {
        if (app) {
            (app as any).$modal.show("loader");
        }
        const subject = [];

    try{
        const body: any = {
            orderId: Number(state.orderId),
        };

        Object.keys(state.formControls).forEach((nameControl) => {
            const control = {...state.formControls[nameControl]};
            const val = control.value;
            if (nameControl === "typeEntre") {
                body[nameControl] =
                    (val === "fl" && 1) || (val === "ip" && 2) || (val === "ur" && 3);
            } else if (control.active) {
                if(nameControl.startsWith("identificationKind")){
                    body[nameControl] = control.value-1;
                } else {
                    body[nameControl] = val;
                }
            }
        });

        if (body.typeEntre === 1 || body.typeEntre === 2) {
            const cn = `${body.surname
                .trim()
                .replace(/"/g, '""')} ${body.name
                .trim()
                .replace(/"/g, '""')} ${body.patronymic.trim().replace(/"/g, '""')}`;
            subject.push(`CN="${cn.trim()}"`);
        } else {
            subject.push(`CN="${body.shortNameOrg.trim().replace(/"/g, '""')}"`);
        }

        if(!body.forInfoSys){
            subject.push(`SN="${body.surname.trim().replace(/"/g, '""')}"`);

            const g = `${body.name
                .trim()
                .replace(/"/g, '""')} ${body.patronymic.trim().replace(/"/g, '""')}`;
            subject.push(`G="${g.trim()}"`);
        }

        subject.push(`C=RU`);

        const regionId = (body.typeEntre === 1 || body.typeEntre === 2)
            ? body.regionRealId
            : body.typeEntre === 3
                ? body.regionLawId
                : undefined;
        const regionName = state.optionsRegion.find(
            ({value}: any) => value === regionId
        )?.label;
        const isRegionFilled = typeof regionId === 'number' && regionId !== 0 && !!regionName;
        if (body.typeEntre === 3 && !isRegionFilled) {
            throw new Error('Ошибка в поле Регион для ЮЛ');
        }
        if (isRegionFilled) { // for body.typeEntre === 1 and 2 (FL and IP) region is not required
            subject.push(`S=${("0" + regionId).slice(-2)} ${regionName}`);
        }

        if(body.cityReal || body.cityLaw) // for body.typeEntre === 2 (IP) city is not required
        {
            subject.push(
                `L="${(body.cityReal || body.cityLaw).trim().replace(/"/g, '""')}"`
            );
        }
        if (body.addressReal || body.addressLaw) {
            subject.push(
                `STREET="${(body.addressReal || body.addressLaw)
                    .trim()
                    .replace(/"/g, '""')}"`
            );
        }

        if (body.typeEntre === 3) {
            subject.push(`O="${body.shortNameOrg.trim().replace(/"/g, '""')}"`);
            if (body.department !== undefined && !isEmpty(body.department)) {
                subject.push(
                    `OU="${body.department.trim().replace(/"/g, '""')}"`
                );
            }
            if(!body.forInfoSys){
                if (body.position !== undefined) {
                    subject.push(`T="${body.position.trim().replace(/"/g, '""')}"`);
                }
            }
        }

        if (body.typeEntre === 2 && body.ogrnip !== undefined) {
            subject.push(`ОГРНИП=${body.ogrnip}`);
        }
        if (body.typeEntre === 3 && body.ogrn !== undefined) {
            subject.push(`ОГРН=${body.ogrn}`);
        }

        if(!body.forInfoSys){
            subject.push(`СНИЛС=${body.snils}`);
        }
        if(body.typeEntre === 3 && body.inn !== undefined){
            // subject.push(`INNLE=${body.inn}`);
            subject.push(`1.2.643.100.4=${body.inn}`);
            if(!body.forInfoSys){
                subject.push(`ИНН=${body.personInn}`)
            }
        }
        if((body.typeEntre === 1 || body.typeEntre === 2) && body.inn !== undefined){
            subject.push(`ИНН=${body.inn}`);
        }
        subject.push(`E=${body.email.trim().replace(/\s/g, "")}`);

            const oids = await dispatch("getOIDS", null, {root: true});
            const certRequest = await crypto.createNewCertRequest(subject.join(";"), oids,body.identificationKind,
                state.isFNSIssueAvailable ? 0 : (state.privateKeyExportPolicy>0 ? 0 : 1) );
            const blob = new Blob([certRequest], {type: "html/text"});
            const form = new FormData();

            form.append("crypto", blob);
            form.append("orderId", state.orderId);
            form.append("typeId", InitialFileTypes.CertificateRequest.toString());
            form.append("certReqFromFilialClient","true");

            const res = await fetch.post(`api/filialClient/upload`, form);

            if(res.data.hasOwnProperty("error")){
                throw new Error(res.data["error"]);
            }
            if (res.status === 200) {
                commit(SET_IS_CERTIFICATE_REQUESTED, res.data.result);
                commit(SET_STATUS_ID, res.data.statusId);
            }
    } catch (err) {
            const error = err as any;
            const errorMessage = JSON.stringify(
                error.response?.data ||
                error.message ||
                'Неизвестная ошибка'
            );
            logError(
                error,
                `Ошибка генерации файла запроса для заявки ${
                    state.orderId
                }, subject:${subject.join(";")}`
            );
            Vue.$toast.error(errorMessage, TOAST_OPTIONS.Error);
    } finally {
            if (app) {
                (app as any).$modal.hide("loader");
            }
    }
    },
    uploadReq: {
        root: true,
        handler: async ({commit, rootGetters}, file) => {
            if (app) {
                (app as any).$modal.show("loader");
            }
            if (!file) {
                return Promise.reject();
            }
            const body = new FormData();
            try {
                body.append("certRequest", file);
                body.append("certReqFromFilialClient","true");
                const {data, status} = await fetch.post(`api/FilialClient/uploadReq/${file.iAppId}`, body);

                if(data.hasOwnProperty("error")){
                    throw new Error(data["error"]);
                }
                
                if (status === 200) {
                    if (rootGetters["productsState/getHasTariffFNS"]) {
                        const certRequest = await fileToBase64(file);
                        commit(SET_NEW_CERT_REQUEST, certRequest);
                    } else {
                        commit(SET_IS_CERTIFICATE_REQUESTED, true);
                        commit(SET_STATUS_ID, 3);
                        //commit(SET_CERT_REQUEST_FILE, await file.text());
                        //return Promise.resolve(data.fields);
                    }
                }
            } catch (err) {
                const error = (err as any);
                logError(error, "Ошибка загрузки файла запроса (загрузка в конце цикла)");

                Vue.$toast.error( ' ' + error.message, {
                    position: POSITION.TOP_LEFT,
                    timeout: 15000,
                    closeOnClick: false,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: false,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
            } finally {
                if (app) {
                    (app as any).$modal.hide("loader");
                }
            }
        }
    },

    async sendToArchive({state}: any) {
        try {
            const res = await fetch.post(
                `api/InitialApplication/sendToArchive`,
                state.orderId,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (res.status === 200) {
                if (!res.data.hasOwnProperty("error") && res.data.result) {
                    router.push("/");
                } else {
                    throw res.data.error;
                }
            }
        } catch (err) {
            const error = (err as any);
            logError(error, `Ошибка при отправке в архив заявки ${state.orderId}`);
            throw error;
        }
    },

    async restoreFromArchive({state}: any) {
        try {
            const res = await fetch.post(
                `api/InitialApplication/restoreFromArchive`,
                state.orderId,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (res.status === 200) {
                if (!res.data.hasOwnProperty("error") && res.data.result) {
                    router.push("/archive");
                } else {
                    throw res.data.error;
                }
            }
        } catch (err) {
            const error = (err as any);
            logError(
                error,
                `Ошибка при восстановлении из архива заявки ${state.orderId}`
            );
            throw error;
        }
    },

    async toggleSendUPDtoEDM({commit}: any, toggle: boolean | null) {
        if (toggle) {
            commit(SET_SEND_UPD_TO_EDM, true);
        } else {
            commit(SET_SEND_UPD_TO_EDM, false);
        }
    },

    async requestUPD({commit, state, dispatch}: any) {
        if (app) {
            (app as any).$modal.show("loader");
        }
        try {
            const url = `api/FilialClient/requestUPD/${state.orderId}?sendUPDtoEDM=${state.isSendUPDtoEDM ? 1 : 0}${"&managerId="+state.managerId}${state.isSendUPDtoEDM ? "&edmType="+state.edmTypeId : ""}`;
            const res = await fetch.get(url);
            // if (app) {
            //   (app as any).$modal.hide("loader");
            // }
            if (res.status === 200) {
                const {result} = res.data;

                if (!res.data.hasOwnProperty("error")) {
                    if (result) {

                        router.go(0);
                    } else {
                        throw new Error("Что-то, пошло не так");
                    }
                } else {
                    let mess = `Ошибка при отправке запроса УПД для заявки ${state.orderId}: ${res.data.error}`;

                    Vue.$toast.error(mess,
                        {
                            position: POSITION.TOP_LEFT,
                            timeout: 7500,
                            closeOnClick: false,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: false,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: "button",
                            icon: true,
                            rtl: false
                        });
                }
            }
        } catch (err) {
            const error = (err as any);
            logError(error, `Ошибка при отправке запроса УПД для заявки ${state.orderId}`);
            let mess = `Ошибка при отправке запроса УПД для заявки ${state.orderId}`;

            if (error.data && error.data.message) {
                mess = error.data.message;
            }
            Vue.$toast.error(mess, {
                position: POSITION.TOP_LEFT,
                timeout: 5000,
                closeOnClick: false,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
        } finally {
            if (app) {
                (app as any).$modal.hide("loader");
            }
        }
    },

    getCustomerEmail(state:any):string {
        return state.formControls.email.value;
    },

    async launchKKMReceipt({commit, state, dispatch}: any){
        if (app) {
            (app as any).$modal.show("loader");
        } 
        try {
            const res = await fetch.get(`api/InitialApplication/launchKKMReceipt/${state.orderId}`);
            if (res.status === 200) {
                const {result} = res.data;

                if (!res.data.hasOwnProperty("error")) {
                    if (result) {

                        router.go(0);
                    } else {
                        throw new Error("Что-то, пошло не так");
                    }
                } else {
                    let mess = `Ошибка при пробитии чека для заявки ${state.orderId}: ${res.data.error}`;

                    Vue.$toast.error(mess,
                        {
                            position: POSITION.TOP_LEFT,
                            timeout: 7500,
                            closeOnClick: false,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: false,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: "button",
                            icon: true,
                            rtl: false
                        });
                }
            }    
        } catch (err) {
            const error = (err as any);
            logError(error, `Ошибка при пробитии чека для заявки ${state.orderId}`);
            let mess = `Ошибка при пробитии чека для заявки ${state.orderId}`;

            if (error.data && error.data.message) {
                mess = error.data.message;
            }
            Vue.$toast.error(mess, {
                position: POSITION.TOP_LEFT,
                timeout: 5000,
                closeOnClick: false,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
        } finally {
            if (app) {
                (app as any).$modal.hide("loader");
            }
        }   
    },

    clearcheckCertInFNSMessage({commit}: any) {
        commit(SET_CHECK_CERT_IN_FNS_MESSAGE, null);
    },

    async enableRevocation({commit,state, dispatch}: any) {
        try {
            (app as any).$modal.hide("existingInFNS");
            const res = await fetch.get(
                `api/InitialApplication/enableRevocation/${state.orderId}`);

            if (res.status === 200) {
                if (!res.data.hasOwnProperty("error") && res.data.result) {
                    //const {checkCertInFNSStatus} = res.data;
                    dispatch("fetchApplication",state.orderId);
                    commit(SET_IS_DOCUMENTS_COLLECTED,false);
                    commit(CLEAR_UPLOAD_FORM_CONTROLS);
                } else {
                    throw res.data.error;
                }
            }
        } catch (err) {
            const error = (err as any);
            logError(error, `Ошибка при подключении заявления на отказ от сертификата для заявки ${state.orderId}`);
            let mess = `Ошибка при подключении заявления на отказ от сертификата для заявки ${state.orderId}`;

                if (error) {
                    mess += `\n${error}`;
                }
                Vue.$toast.error(mess, {
                    position: POSITION.TOP_LEFT,
                    timeout: 5000,
                    closeOnClick: false,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: false,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
        }
    },

    async signWithIgnore({commit,state, dispatch}: any){
        // try {
        //     (app as any).$modal.hide("existingInFNS");
        //     const res = await fetch.get(
        //         `api/InitialApplication/setIgnoreExistingFnsCert/${state.orderId}`);

        //     if (res.status === 200) {
        //         if (!res.data.hasOwnProperty("error") && res.data.result) {
        //             dispatch("fetchApplication",state.orderId);
        //             Vue.$toast.success("Режим игнорирования существующего сертификата ФНС установлен. Повторите подпись и отправку документов", {
        //                 position: POSITION.TOP_LEFT,
        //                 timeout: 5000,
        //                 closeOnClick: false,
        //                 pauseOnFocusLoss: true,
        //                 pauseOnHover: true,
        //                 draggable: false,
        //                 draggablePercent: 0.6,
        //                 showCloseButtonOnHover: false,
        //                 hideProgressBar: true,
        //                 closeButton: "button",
        //                 icon: true,
        //                 rtl: false
        //             });
        //         } else {
        //             throw res.data.error;
        //         }
        //     }
        // } catch (error) {
        //     logError(error, `Ошибка при изменении режима подписи для заявки ${state.orderId}`);
        //     let mess = `Ошибка при изменении режима подписи для заявки ${state.orderId}`;

        //         if (error) {
        //             mess += `\n${error}`;
        //         }
        //         Vue.$toast.error(mess, {
        //             position: POSITION.TOP_LEFT,
        //             timeout: 5000,
        //             closeOnClick: false,
        //             pauseOnFocusLoss: true,
        //             pauseOnHover: true,
        //             draggable: false,
        //             draggablePercent: 0.6,
        //             showCloseButtonOnHover: false,
        //             hideProgressBar: true,
        //             closeButton: "button",
        //             icon: true,
        //             rtl: false
        //         });
        // }    
        (app as any).$modal.hide("existingInFNS");
        if (state.currentCertThumbprint) {
            const formBody: { [key: string]: string | Blob } = {};
            const сertificate = crypto.certs.find(
                (x) => x.value === state.currentCertThumbprint
            );

            if (сertificate === undefined) {
                throw new Error("Не выбран сертификат.");
            }

            formBody.hashAlg = сertificate.alg.toString();
            formBody.typeId = String(InitialFileTypes.DocumentsZip);

            try {
                commit(SET_IS_UPLOAD_FORM_LOADED, false);

                const {hash} = await dispatch(
                    state.isDocumentsCollected ? "fetchUpload" : "handlerSaveDocuments",
                    formBody
                );

                if (await !dispatch("validateCert")) {
                    return;
                }

                const signedHash = await crypto.signHash(
                    hash,
                    state.currentCertThumbprint
                );

                if (!signedHash || isEmpty(signedHash)) {
                    commit(
                        SET_ERROR_MESSAGE,
                        "Не удалось подписать заявление. Попробуйте ещё раз или обратитесь в поддержку!"
                    );
                } else {
                    const {newStatusId} = await dispatch("fetchUpload", {
                        signedHash: new Blob([signedHash], {type: "html/text"}),
                        typeId: String(InitialFileTypes.DocumentsZipSig),
                        signWithIgnore: true,
                    });
                    if (newStatusId != STATUSES.HeadManagerSign.id) {
                        commit(SET_IS_EDIT_UPLOAD, false);
                        commit(SET_IS_DOCUMENTS_COLLECTED, true);
                        (app as any).$modal.hide("confirm");
                        await up(commit.bind(null, NEXT_ACTIVE_TAB));
                    }
                }
            } catch (error) {
                throw error;

            } finally {
                commit(SET_IS_UPLOAD_FORM_LOADED, true);
            }
        }
    },

    checkValidityAndScroll({dispatch}){
        dispatch("checkValidity");
        scrollToInvalidFormItem();
    },

    async refreshSmevStatus({commit}, {applicationId, smevRequestType}) {
        const body = {applicationId, smevRequestType};
        try {
            const res = await fetch.post(`api/filialClient/smevCheck`, body);
            if (res.status === 200) {
                const message = "Запрос на перепроверку статуса СМЭВ отправлен. Для отображения результата обновите страницу"
                Vue.$toast.success(message, {
                    position: POSITION.TOP_LEFT,
                    timeout: 6000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: false,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
            } else if (res.status === 208) {
                const message = res.data;
                Vue.$toast.error(message, {
                    position: POSITION.TOP_LEFT,
                    timeout: 6000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: false,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
            } else {
                throw new Error("Неизвестная ошибка перепроверки статусов СМЭВ")
            }
        } catch (err) {
            const error = (err as any);
            const message = "Ошибка перепроверки статуса СМЭВ";
            Vue.$toast.error(message, {
                position: POSITION.TOP_LEFT,
                timeout: 6000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
            logError(error, message);
            throw error;
        }
    },

    /*
     если в заявке выбран способ идентификации как "Лично",
     то фото является обязательным документом к прикреплению
    */
    definePhotoRequired({commit, state}) {
        const isPersonally = state.formControls["identificationKind"].value === IDENTIFICATION_KINDS.Personaly.value;
        commit(SET_DOCUMENT_UPLOAD_REQUIRED, {fieldUploadName: "foto", required: isPersonally});
    },

    /*
     если в заявке выбран способ идентификации как "Лично",
     то паспорт является обязательным документом к прикреплению
    */
    definePassportRequired({commit, state}) {
        const isPersonally = state.formControls["identificationKind"].value === IDENTIFICATION_KINDS.Personaly.value;
        commit(SET_DOCUMENT_UPLOAD_REQUIRED, {fieldUploadName: "passport", required: isPersonally});
    },

    /*
     если выбран продукт 820 "Создание и выдача квалифицированного сертификата ключа
     проверки электронной подписи (КСКПЭП) сотрудника юридического лица", 
     то доверенность является обязательным документом к прикреплению
    */
    defineProxyRequired({commit, rootState}: any) {
        const hasTargetProduct = rootState["productsState"].productsSelected.includes(820);
        commit(SET_DOCUMENT_UPLOAD_REQUIRED, {fieldUploadName: "proxy", required: hasTargetProduct});
    },

    /*
     если в заявке выбраны продукты с ЭДО и код ФНС задан, отображать и проверять код ФНС
    */
    defineFnsCodeActive({state, rootGetters, dispatch}) {
        if (!!state.formControls.fnsCode.value && rootGetters["productsState/getHasProductsWithEDM"]) {
            dispatch("setFormControlProps", {name: "fnsCode", props: {active: true, required: true}})
        }
    },

    recieveStatusFromInitialApplicationHub({dispatch}, statusId) {
        const status = Object.values(STATUSES).find((s: any) => s.id == statusId)?.title;
        dispatch("initStatus", {status, statusId});
    }, 

    /*
     заменяет/добавляет свойство у выбранного control
    */
    setFormControlProps({state, commit}, {name, props}: {name: TControlName, props: any}) {
        const control = {...state.formControls[name], ...props};
        commit(SET_FORM_CONTROL, {control, name});
    },

    /*
     изменяет id системы ЭДО
    */
    setEdmTypeId({commit}, edmTypeId: number | null) {
        commit(SET_EDM_TYPE_ID, edmTypeId);
    },

    /*
     инициализирует документы на вкладке "Документы (клиент)" при фечинге заявки
    */
    initUploads({state, commit}, {uploads, scans}: {uploads: any[], scans: any[]}) {
        uploads.forEach((upload: any) => {
            const name = Object.keys(state.uploadFormControls)
                .find((key: any) => state.uploadFormControls[key as TFieldUploadName].fileType === upload.typeId);
            if (
                (name === "extra" && upload.downloadable) ||
                (name && name !== "extra" && upload.downloadable && state.isDocumentsCollected)
            ) {
                const control = {...state.uploadFormControls[name as TFieldUploadName]};
                control.isValid = true;
                control.isTouched = true;
                control.isCollected = true;
                commit(SET_FORM_CONTROLS_FILE, {control, name});
            }
        })
    },

    /*
     сохраняет в заявке документ типа "Другой документ"
    */
    async requestAnotherUpload({state, commit}, {file, name}: any) {
        if (app) {
            (app as any).$modal.show("loader");
        }
        try {
            const fileBase64 = await fileToBase64(file);
            const initialApplicationId = state.orderId;
            const fileName = file.name.replace(extname(file.name), "");
            const fileId = state.anotherUploads[name].fileId || null;
            const body = {
                initialApplicationId,
                file: fileBase64,
                fileName,
                fileId
            };
            const res = await fetch.post(`/api/file/uploadOtherFile`, body);
            if (res.status === 200 && res.data && res.data.success) {
                const control = {...state.anotherUploads[name], fileId: res.data.fileId};
                commit(SET_ANOTHER_UPLOAD, {control, name});
                const message = `Документ "${fileName}" успешно загружен`
                Vue.$toast.success(message, TOAST_OPTIONS.Success);
            } else {
                const message = res.data && res.data.error ?
                    `Ошибка при загрузке документа "${fileName}". ${res.data.error}` :
                    `Неизвестная ошибка при загрузке документа ${fileName}`;
                throw new Error(message);
            }
        } catch (err) {           
            const error = (err as any);
            const message = error.message || "Ошибка при загрузке документа";
            Vue.$toast.error(message, TOAST_OPTIONS.Error);
            logError(error, message);
            throw err;
        } finally {
            if (app) {
                (app as any).$modal.hide("loader");
            }
        }  
    },

    /*
     подгружает информацию о "других документах" при переходе на вкладку "Документы (клиент)"
    */
    async fetchAnotherUploads({state, commit}) {
        if (app) {
            (app as any).$modal.show("loader");
        }
        try {
            const body = {applicationId: state.orderId};
            const res = await fetch.post(`/api/file/getOtherFiles`, body);
            if (res.status === 200 && res.data && res.data.success) {
                const compiled = template(
                    `<ul>
                        <li> <%- fileName %> </li>
                    </ul>`
                );
                const {files} = res.data;
                files.forEach((item: any, index: number) => {
                    const {fileName, fileId} = item;
                    const name = Object.keys(state.anotherUploads)[index];
                    const control = {
                        ...state.anotherUploads[name],
                        isValid: true,
                        isTouched: true,
                        isWatchable: true,
                        descr: compiled({fileName}),
                        fileId
                    }
                    commit(SET_ANOTHER_UPLOAD, {control, name});
                })
            } else {
                const message = res.data && res.data.error ?
                    `Ошибка при загрузке других документов. " ${res.data.error}` :
                    "Неизвестная ошибка при загрузке других документов";
                throw new Error(message);
            }
        } catch(err) {
            const error = (err as any);
            const message = error.message || "Неизвестная ошибка при загрузке других документов";
            Vue.$toast.error(message, TOAST_OPTIONS.Error);
            logError(error, message);
        } finally {
            if (app) {
                (app as any).$modal.hide("loader");
            }
        }
    }, 

    /*
     подгружает "другой документ" и открывает его в новой вкладке
    */
    async fetchAnotherUpload({state,}, name: string) {
        try {
            const body = {fileId: state.anotherUploads[name].fileId};
            const res = await fetch.post(`/api/file/getOtherFile`, body);
            if (res.status === 200 && res.data && res.data.success) {
                const {file, name: fileName, header} = res.data;
                const {fileJS, contentType} = base64ToFile(fileName, header, file);
                if (contentType.match(/(jpg|jpeg|png|gif|pdf|PDF|JPG|PNG)/)) {
                    const url = URL.createObjectURL(fileJS);
                    window.open(url, '_blank');
                } else {
                    download(fileJS, decodeURI(fileName), contentType);  
                }
            } else {
                const message = res.data && res.data.error ?
                    `Ошибка при загрузке документа "${state.anotherUploads[name].fileName}". " ${res.data.error}` :
                    `Неизвестная ошибка при загрузке документа "${state.anotherUploads[name].fileName}"`;
                throw new Error(message);
            }
        } catch(err) {
            const error = (err as any);
            const message = error.message || "Неизвестная ошибка при загрузке документа";
            Vue.$toast.error(message, TOAST_OPTIONS.Error);
            logError(error, message);
        }
    },

    /*
     подгружает данные заявки из БАЗИС АПИ
    */
    async fetchApplicationFromBasisApi({state, commit, dispatch}) {
        if (app) {
            (app as any).$modal.show("loader");
        }
        try {
            const typeEntre = state.formControls.typeEntre.value;
            const ogrn = (() => {
              const basisFields = state.fieldsFromBasisApi;
              const ogrnIpValue = state.formControls.ogrnip.value;
              const ogrnValue = state.formControls.ogrn.value;
              const innValue = state.formControls.inn.value;

              if (
                Object.keys(basisFields).length &&
                basisFields.inn !== innValue
              ) {

                if (typeEntre === "ip") {
                  if (basisFields.ogrnip === ogrnIpValue) {
                    commit(SET_FORM_CONTROL, {
                      control: { ...state.formControls.ogrnip, value: "" },
                      name: "ogrnip",
                    });
                    return "";
                  }

                  return ogrnIpValue;
                }

                if (typeEntre === "ur") {
                  if (basisFields.ogrn === ogrnValue) {
                    commit(SET_FORM_CONTROL, {
                      control: { ...state.formControls.ogrn, value: "" },
                      name: "ogrn",
                    });
                    return "";
                  }

                  return ogrnValue;
                }
              }

              return typeEntre === "ur" ? ogrnValue : ogrnIpValue;
            })();

            const body: { [key: string]: any } = {
              InitialApplicationType: typeEntre === "ur" ? 1 : 0,
              inn: state.formControls.inn.value,
              ogrn
            };

            // Если повторная отправка (Отправка по ОГРН или ОГРНИП)
            if (state.isFilledByOGRN && state.requestGuid) {
                body.requestGuid = state.requestGuid;
                commit(SET_REQUEST_GUID, null);
            }

            const res = await fetch.post<IFieldsFromBasisApi>(`/api/initialApplication/getFieldsFromBasis`, body);

            if (res.status === 200 && res.data && res.data.status === BASIC_STAUSES.SUCCESS) {
                const {
                  error,
                  status,
                  ogrn,
                  ogrnIp,
                  requestGuid,
                  regionRealId,
                  regionLawId, 
                  ...data
                } = res.data;

                if (data.directors!.length) {
                    (app as any).$modal.show("modal-director-autofill");
                }

                if (typeEntre === 'ur') {
                    commit(SET_FIELDS_FROM_BASIS_API, {...data, ogrn, regionLawId});
                } else {
                    commit(SET_FIELDS_FROM_BASIS_API, {...data, ogrnip: ogrnIp, regionRealId});
                }

                dispatch("fillFormWithBasisApi");
            } else {
                if (res.data.status === BASIC_STAUSES.FOUN_MORE_WITH_THAT_INN) {
                    const foundedOgrnIpInputs = Array.from(document.getElementsByName('ogrnip'));
                    foundedOgrnIpInputs[0].focus();
                    commit(TOGGLE_IS_FILLED_BY_ORGN);
                }

                const message = res.data && res.data.error ?
                    res.data.error :
                    "Неизвестная ошибка при загрузке других документов";
                commit(SET_REQUEST_GUID, res.data.requestGuid);
                throw new Error(message);
            }

            if (state.isFilledByOGRN && state.formControls.ogrnip.isValid) commit(TOGGLE_IS_FILLED_BY_ORGN);
        } catch(err) {
            const error = (err as any);
            const message = error.message || "Неизвестная ошибка при загрузке других документов";
            Vue.$toast.error({
                component: ToastWithCopy,
                props: {
                    message,
                    requestGuid: state.requestGuid
                }
            }, TOAST_OPTIONS.Error);
            logError(error, message);
        } finally {
            if (app) {
                (app as any).$modal.hide("loader");
            }
        }
    },

    /*
     повторяет проверку в ФНС на действующие сертификаты
    */
     async refreshFnsStatus({state, commit}) {
        if (app) {
            (app as any).$modal.show("loader");
        }
        try {
            const res = await fetch.get(`api/certificate/checkCertInFns/${state.orderId}`);
            if (res.status === 200 && res.data.success) {
                commit(CLEAN_FNS_EXISTING_CERTS);
                Vue.$toast.success("Запрос на проверку сертификатов ФНС отправлен", TOAST_OPTIONS.Error);
            } else {
                const message = res.data && res.data.errorMessage ?
                    `Ошибка при попытке повторить проверку ФНС. " ${res.data.errorMessage}` :
                    "Неизвестная ошибка при попытке повторить проверку ФНС";
                throw new Error(message);
            }
        } catch(err) {
            const error = (err as any);
            const message = error.body || error.message || "Неизвестная ошибка при попытке повторить проверку ФНС";
            Vue.$toast.error(message, TOAST_OPTIONS.Error);
            logError(error, message);
        } finally {
            if (app) {
                (app as any).$modal.hide("loader");
            }
        }
    },

    /*
     заполняет форму автоматически из файла запроса
    */
    async fillFormWithFile({dispatch, commit}, evt: any) {
        const file = evt.target.files[0];
        evt.target.value = "";
        commit(SET_FIELDS_FROM_BASIS_API, {});

        if (file) {
            try {
                const fields = await dispatch("parseCertRequest", file, {root: true});
                dispatch("autoFillFields", {fields, disableFields: true});
            } catch (err) {
                const error = (err as any);
                logError(error);
                (app as any).$modal.show("dialog", {
                    title: "Ошибка",
                    text: error.message,
                    buttons: [
                        {
                            title: "ПРОДОЛЖИТЬ",
                            default: true,
                            handler: () => {
                                (app as any).$modal.hide("dialog");
                            },
                        },
                    ],
                });

                throw error;
            }
        }
    },

    /*
     заполняет форму автоматически из данных БАЗИС АПИ
    */
    fillFormWithBasisApi({state, dispatch, commit}, selectedDirectorModal: any) {
        let {addressFNS, ...fields} = state.fieldsFromBasisApi;
        const typeEntre = state.formControls.typeEntre.value;
        
        dispatch('clearAllActiveFieldsValues');

        // Автозаполнение выбранного директора
        if (
          typeEntre === "ur" &&
          selectedDirectorModal &&
          "director" in selectedDirectorModal
        ) {
          const { director, isDirector } = selectedDirectorModal;
          const {inn, ...selectedDirector} = fields.directors![director];
          const {nameOrg, patronymicOrg, surnameOrg, positionDirector} = selectedDirector;
          fields = {
            ...fields,
            ...selectedDirector,
            name: isDirector && nameOrg,
            surname: isDirector && surnameOrg,
            patronymic: isDirector && patronymicOrg,
            position: isDirector && positionDirector,
            isDirector,
            personInn: isDirector ? inn : ''
          };
        }

        try {
            dispatch("autoFillFields", {fields, disableFields: false});
        } catch (err) {
            const error = (err as any);
            (app as any).$modal.show("dialog", {
                title: "Ошибка",
                text: error.message,
                buttons: [
                    {
                        title: "ПРОДОЛЖИТЬ",
                        default: true,
                        handler: () => {
                            (app as any).$modal.hide("dialog");
                        },
                    },
                ],
            });
            throw error;
        }
    },
    
    setNewCertRequest({commit}, certRequest: any) {
        commit(SET_NEW_CERT_REQUEST, certRequest);
    },

    initStatus({commit}, {statusId, status}: {statusId: number, status: string}) {
        if (statusId === STATUSES.EDORegistrationError.id) {
            commit(SET_STATUS_ID, STATUSES.EDORegistration.id);
            commit(SET_STATUS, STATUSES.EDORegistration.title);
        } else {
            commit(SET_STATUS_ID, statusId);
            commit(SET_STATUS, status);
        }
    },

    initFields({commit, state, dispatch}, {application, payer}: any) {
        commit(SET_TYPE_SELECTION, application.typeEntre);
        Object.keys(state.formControls).forEach((nameControl) => {
            const control: any = {...state.formControls[nameControl as TControlName]};

            if (nameControl !== "typeEntre") {
                
                const val: any = !nameControl.startsWith("payer")
                    ? application[nameControl]
                    : payer[getPayerFieldName(nameControl)];

                if (isObject(val)) {
                    Object.keys(val).forEach((name) => {
                        if (control.hasOwnProperty(name)) {
                            control[name] = (val as any)[name];
                        }
                    });
                } else if (val !== null) {
                    if(nameControl.startsWith("identificationKind")) {
                        control.value=val+1;
                    } else if (nameControl.startsWith("addovert")) {
                        // присоединение к оферте в значении "есть" ставится по умолчанию для ФЛ в рамках задачи #365
                        control.value = application.typeEntre === "fl" ? 1 : val;
                    } else if (nameControl == "payerSubjectType") {
                        // для ФЛ плательщик сам заявитель(ФЛ) по умолчанию в рамках задачи #367
                        const payerSubjectType = Object.values(PAYER_TYPES).find(type => type.id === val);
                        control.value =  payerSubjectType ? payerSubjectType.value : PAYER_TYPES.fl.value;
                    } else {
                        control.value = val === undefined ? control.value : val;
                    }
                }
                
                if (nameControl === "serialDoc") {
                    control.rules = application.identificationDocumentTypeId === undefined
                    || application.identificationDocumentTypeId === IDENTIFICATION_DOCUMENT_TYPES.russian.id
                    ? {...control.rules, empty: control.required, minLength: 10}
                    : {...control.rules, empty: control.required};
                } else {
                    control.rules = {...control.rules, empty: control.required};
                }

                control.disabled = true;
                commit(SET_FORM_CONTROL, {control, name: nameControl});
            }
        });

        commit(DEFINE_FIELDS_ACTIVE, state.formControls.typeEntre.value);
        commit(DEFINE_IDENTIFICATION_DOCUMENT_FIELDS_ACTIVE, state.formControls.identificationDocumentTypeId.value);
        if (application.typeEntre === "fl") {
            commit(DEFINE_PAYER_FIELDS_ACTIVE, state.formControls.payerSubjectType.value);
        }

        dispatch("checkValidity");
    },

    clearAllActiveFieldsValues({ state, commit }) {
        Object.entries(state.formControls).forEach(([ key, field ]) => {
            if (field.active && field.value) {
                commit(CLEAR_FIELD_VALUE, key);
            }
        })
    },
    /*
     отправляет на сервер прикрепленные сканы документов "Подписанный сертификат" и "Руководство по безопасности"
    */
    async uploadApplicantSignedDocuments({commit, state}) {
        app && (app as any).$modal.show("loader");
        try {
            const certControl = {...state.uploadFormControls.certificateSigned};
            const securityInfoControl = {...state.uploadFormControls.securityInformationSigned};
            const printingFormSignatureControl = {...state.uploadFormControls.printingFormSignature};
            const files: any[] = [];
            if (certControl.files && certControl.files.length) {
                certControl.isCollected = true;
                const fileContent = await fileToBase64(certControl.files[0])
                files.push({fileContent, fileType: InitialFileTypes.ApplicantSignedPrintingForm});
            }
            if (securityInfoControl.files && securityInfoControl.files.length) {
                securityInfoControl.isCollected = true;
                const fileContent = await fileToBase64(securityInfoControl.files[0])
                files.push({fileContent, fileType: InitialFileTypes.ApplicantSignedSecurityInstructionForm});
            }
            if (printingFormSignatureControl.files && printingFormSignatureControl.files.length) {
                printingFormSignatureControl.isCollected = true;
                const fileContent = await fileToBase64(printingFormSignatureControl.files[0])
                files.push({fileContent, fileType: InitialFileTypes.PrintingFormSignature});
            }
            const initialApplicationId = Number(state.orderId);
            const fileId = null || certControl.fileId || securityInfoControl.fileId;
            const body = {
                initialApplicationId,
                fileId,
                files
            };
            const res = await fetch.post(`/api/file/uploadApplicantSignedDocuments`, body);
            if (res.status === 200 && res.data && res.data.success) {
                commit(SET_FORM_CONTROLS_FILE, {control: {...certControl, fileId: res.data.fileId}, name: "certificateSigned"});
                commit(SET_FORM_CONTROLS_FILE, {control: {...securityInfoControl, fileId: res.data.fileId}, name: "securityInformationSigned"});
                commit(SET_FORM_CONTROLS_FILE, {control: {...printingFormSignatureControl, fileId: res.data.fileId}, name: "printingFormSignature"});
                const message = `Документы успешно загружены`
                Vue.$toast.success(message, TOAST_OPTIONS.Success);
            } else {
                const message = res.data && res.data.error ?
                    `Ошибка при загрузке документов. ${res.data.error}` :
                    `Неизвестная ошибка при загрузке документов`;
                throw new Error(message);
            }
        } catch (err) {           
            const error = (err as any);
            const message = error.message || "Ошибка при загрузке документов";
            Vue.$toast.error(message, TOAST_OPTIONS.Error);
            logError(error, message);
        } finally {
            app && (app as any).$modal.hide("loader");
        }
    },

    /*
     скачивает архив со сканированными документами "Подписанный сертификат" и "Руководство по безопасности"
    */
    async getApplicantSignedArchiveWithDocs({state}) {
        try {
            const body = {initialApplicationId: Number(state.orderId)};
            const res = await fetch.post(`/api/file/getApplicantSignedArchiveWithDocs`, body);
            if (res.status === 200 && res.data && res.data.success && res.data.fileContent) {
                const fileName = `Подписанный_сертификат_и_руководство_${state.orderId}`;
                const {fileJS, contentType} = base64ToFile(fileName, res.data.fileHeader, res.data.fileContent);
                download(fileJS, decodeURI(fileName), contentType);
            } else {
                const message = res.data && res.data.error ?
                    `Ошибка при попытке скачать документы. ${res.data.error}` :
                    `Неизвестная ошибка при попытке скачать документы`;
                throw new Error(message);
            }
        } catch (err) {           
            const error = (err as any);
            const message = error.message || "Ошибка при попытке скачать документы";
            Vue.$toast.error(message, TOAST_OPTIONS.Error);
            logError(error, message);
        }
    },

    /*
     вызывается при загрузке заявки в fetchApplication()
     возвращает информацию о содержании архива со сканированными документами "Подписанный сертификат" и "Руководство по безопасности"
    */
    async getApplicantSignedDocsListFromArchive({commit, state}) {
        !!app && (app as any).$modal.show("loader");
        try {
            const body = {initialApplicationId: Number(state.orderId)};
            const res = await fetch.post(`/api/filialClient/getApplicantSignedDocsListFromArchive`, body);
            if (res.status === 200 && res.data && res.data.success && res.data.files) {
                if (res.data.files.length) {
                    const certControl = {
                        ...state.uploadFormControls.certificateSigned,
                        fileId: res.data.fileId
                    };
                    const securityInfoControl = {
                        ...state.uploadFormControls.securityInformationSigned,
                        fileId: res.data.fileId
                    };
                    const printingFormSignatureControl = {
                        ...state.uploadFormControls.printingFormSignature,
                        fileId: res.data.fileId
                    };
                    if (res.data.files.find((file: any) => file.fileType === InitialFileTypes.ApplicantSignedPrintingForm)) {
                        certControl.isCollected = true;
                        certControl.isValid = true;
                        certControl.isWatchable = true;
                        commit(SET_FORM_CONTROLS_FILE, {control: certControl, name: "certificateSigned"});
                    }
                    if (res.data.files.find((file: any) => file.fileType === InitialFileTypes.ApplicantSignedSecurityInstructionForm)) {
                        securityInfoControl.isCollected = true;
                        securityInfoControl.isValid = true;
                        securityInfoControl.isWatchable = true;
                        commit(SET_FORM_CONTROLS_FILE, {control: securityInfoControl, name: "securityInformationSigned"});
                    }
                    if (res.data.files.find((file: any) => file.fileType === InitialFileTypes.PrintingFormSignature)) {
                        printingFormSignatureControl.isCollected = true;
                        printingFormSignatureControl.isValid = true;
                        printingFormSignatureControl.isWatchable = true;
                        commit(SET_FORM_CONTROLS_FILE, {control: printingFormSignatureControl, name: "printingFormSignature"});
                    }
                }              
            } else {
                const message = res.data && res.data.error ?
                    `Ошибка отображения документов клиента. ${res.data.error}` :
                    `Неизвестная ошибка отображения документов клиента`;
                throw new Error(message);
            }
        } catch (err) {           
            const error = (err as any);
            const message = error.message || "Ошибка отображения документов клиента";
            Vue.$toast.error(message, TOAST_OPTIONS.Error);
            logError(error, message);
        } finally {
            !!app && (app as any).$modal.hide("loader");
        }
    },

    async getApplicantSignedDocument({state}: any, fileType: number) {
        !!app && (app as any).$modal.show("loader");
        try {
            const body = {initialApplicationId: Number(state.orderId)};
            const res = await fetch.post(`/api/file/getApplicantSignedDocuments`, body);
            if (res.status === 200 && res.data.success && Array.isArray(res.data.files)) {
                const file = res.data.files.find((f: any) => f.fileType === fileType);
                if (file) {
                    const {fileJS, contentType} = base64ToFile(file.fileName, file.fileHeader, file.fileContent);
                    if (contentType.match(/(jpg|jpeg|png|gif|pdf|PDF|JPG|PNG)/)) {
                        const url = URL.createObjectURL(fileJS);
                        window.open(url, '_blank');
                    } else {
                        download(fileJS, decodeURI(file.fileName), contentType);
                    }
                } else {
                    throw new Error("Запрашиваемый документ в заявке отсутствует");
                }
            } else {
                const message = res.data && res.data.error ?
                `Ошибка при попытке просмотреть документ. " ${res.data.error}` :
                `Неизвестная ошибка при попытке просмотреть документ`;
                throw new Error(message);
            }
        } catch (err) {
            const error = (err as any);
            const message = error.message || "Неизвестная ошибка при попытке просмотреть документ";
            Vue.$toast.error(message, TOAST_OPTIONS.Error);
            logError(error, message);
        } finally {
            !!app && (app as any).$modal.hide("loader");
        }
    },

    defineActivePayerFileds({commit}, payerSubjectType: TPayerType) {
        commit(DEFINE_PAYER_FIELDS_ACTIVE, payerSubjectType);
    },
    
    /*
    получает и показывает qr платежа по СБП
    */
    async fetchQrPayment({commit, state, dispatch}: any){
        if (app) {
            (app as any).$modal.show("loader");
        } 
        try {
            const res = await fetch.get(`api/FilialClient/info/${state.orderId}`);
            if (res.status === 200) {
                const {paymentData, info} = res.data;
                //console.log(info);
                //console.log(paymentData);
                const {payload} = paymentData;
                let url;
                if (payload) {
                    const blob = base64toBlob(payload, 'image/png');
                    url = blobToUrl(blob, '');
                }
                commit(SET_QR_URL,url);
                (app as any).$modal.show("qrPayment");
            }    
        } catch (err) {
            const error = (err as any);
            logError(error, `Ошибка при платеже для заявки ${state.orderId}`);
            let mess = `Ошибка при платеже для заявки ${state.orderId}`;

            if (error.data && error.data.message) {
                mess = error.data.message;
            }
            Vue.$toast.error(mess, {
                position: POSITION.TOP_LEFT,
                timeout: 5000,
                closeOnClick: false,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
        } finally {
            if (app) {
                (app as any).$modal.hide("loader");
            }
        }   
    },

    async openInstructionDocument({state}: any) {
        const fileUrl = "https://uc-osnovanie.ru/docs/Instrukciya_vypusk_EP_s_pomoshchyu_LKKL_V_00.01.pdf";
    
        window.open(fileUrl, "_blank");
    },

    // эти методы пока не востребованы
    // async sendExtraPaymentSubject({state}: any) {
    //     try {
    //         const body: {[key:string]: any} = {
    //             applicationId: Number(state.orderId),
    //             applicationType: APPLICATION_TYPES.Issue.id
    //         };

    //         Object.keys(state.formControls)
    //         .filter(key => key.startsWith("payer"))
    //         .forEach((nameControl) => {
    //             const control = {...state.formControls[nameControl]};
    //             if (control.active) {
    //                 const val = control.value;
    //                 let name = nameControl.replace("payer", "");
    //                 name = name.charAt(0).toLowerCase() + name.slice(1);
    //                 (body[name] = typeof val === "string" ? val.trim() : val);
    //             }
    //         });

    //         const res = await fetch.post(`/api/ExtraPaymentSubject`, body);
    //         if (res.status === 200) {
    //             return null;
    //         } else {
    //             const message = res.data && res.data.error ?
    //             `Ошибка при попытке сохранить данные плательщика. " ${res.data.error}` :
    //             `Неизвестная ошибка при попытке сохранить данные плательщика.`;
    //             throw new Error(message);
    //         }
    //     } catch (err) {
    //         const error = (err as any);
    //         const message = error.message || "Неизвестная ошибка при попытке сохранить данные плательщика.";
    //         throw new Error(message);
    //     }
    // },

    // async fetchExtraPaymentSubject({commit, state}: any) {
    //     try {
    //         const res = await fetch.get(`api/ExtraPaymentSubject?applicationId=${state.orderId}&applicationType=${APPLICATION_TYPES.Issue.id}`);
    //         if (res.status === 200 && res.data) {
    //             Object.keys(state.formControls)
    //             .filter(key => key.startsWith("payer") && key !== "payerSubjectType")
    //             .forEach((nameControl) => {
    //                 const control: any = {...state.formControls[nameControl as TControlName]};
    //                 let name = nameControl.replace("payer", "");
    //                 name = name.charAt(0).toLowerCase() + name.slice(1);
    //                 if (res.data.hasOwnProperty(name) && res.data[name] !== null) {
    //                     control.value = res.data[name];
    //                     commit(SET_FORM_CONTROL, {control, name: nameControl});
    //                 }
    //             });
    //         } else {
    //             const message = res.data && res.data.error ?
    //             `Ошибка при попытке получить данные плательщика. " ${res.data.error}` :
    //             `Неизвестная ошибка при попытке получить данные плательщика.`;
    //             throw new Error(message);
    //         }
    //     } catch (err) {
    //         const error = (err as any);
    //         const message = error.message || "Неизвестная ошибка при попытке получить данные плательщика.";
    //         throw new Error(message);
    //     }
    // },
};
