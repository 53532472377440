var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_c('Button',{staticClass:"instruction-btn",on:{"click":function($event){$event.preventDefault();return _vm.openInstructionDocument.apply(null, arguments)}}},[_vm._v(" Инструкция ")]),_c('Tabs',{attrs:{"tabIndex":_vm.activeTab},on:{"change":_vm.setActiveTab}},[_c('Tab',{attrs:{"title":"Форма заявки"}},[_c('ProposalForm')],1),_c('Tab',{attrs:{"title":"Документы","disabled":!_vm.isDocumentDownload || _vm.getIsSystemProcessing || _vm.isPdnUsingProhibited}},[(!_vm.isDocumentsCollected && _vm.statusId === _vm.getApplicationStatuses.DocumentsSending.id)?_c('ProposalFormDocument',{attrs:{"title":"Документы по Вашей заявке","descr":"Для успешного продолжения процесса перевыпуска их необходимо подписать.","typeDownload":true,"documents":_vm.getDownloadDocuments},on:{"openSignModal":function($event){return _vm.$emit('openSignModal')}}}):_c('ProposalFormDocument',{attrs:{"title":"Документы готовы","typeCollected":true,"documents":_vm.getDownloadDocuments},on:{"openSignModal":function($event){return _vm.$emit('openSignModal')}}})],1),_c('Tab',{attrs:{"title":"Сертификат","disabled":!_vm.isDocumentsCollected || _vm.isEdit || _vm.getIsSystemProcessing || _vm.isPdnUsingProhibited}},[(!_vm.isCertificateRequested && ![_vm.getApplicationStatuses.Moderation.id,
          _vm.getApplicationStatuses.CertificateIssue.id,
          _vm.getApplicationStatuses.CertificateReady.id,
          _vm.getApplicationStatuses.WaitingForOriginals.id,
          _vm.getApplicationStatuses.OriginalsIncorrect.id,
          _vm.getApplicationStatuses.Rejection.id].includes(_vm.statusId) && !_vm.isArchive)?_c('ProposalFormDocument',{staticClass:"tab-4",attrs:{"title":"Создайте запрос","descr":"Для завершения процесса формирования заявки необходим файл запроса сертификата ЭП."}},[_c('Checkbox',{attrs:{"name":"privateKeyExportPolicy","label":"Не экспортируемый ключ","checked":_vm.isFNSIssueAvailable || _vm.privateKeyExportPolicy>0,"disabled":_vm.isFNSIssueAvailable,"value":_vm.privateKeyExportPolicy>0},on:{"change":_vm.changePrivateKeyExportPolicy}}),_c('div',{staticClass:"button-wrapper"},[_c('Button',{attrs:{"disabled":_vm.isManagerPartner},on:{"click":_vm.genRequestFile}},[_vm._v(" Сгенерировать ")])],1)],1):((_vm.isCertificateRequested && _vm.statusId !== _vm.getApplicationStatuses.Rejection.id) || [_vm.getApplicationStatuses.Moderation.id,
          _vm.getApplicationStatuses.CertificateIssue.id,
          _vm.getApplicationStatuses.CertificateReady.id,
          _vm.getApplicationStatuses.WaitingForOriginals.id,
          _vm.getApplicationStatuses.OriginalsIncorrect.id].includes(_vm.statusId))?_c('ProposalFormDocument',{staticClass:"tab-4 complete",attrs:{"title":_vm.certificate.length ? 'Сертификат выпущен!' : 'Запрос отправлен'}},[(_vm.certificate.length)?[(
              (_vm.uploadFormControls.certificateSigned.fileId || _vm.uploadFormControls.printingFormSignature.fileId) &&
              !_vm.hideUtd &&
              !_vm.isUPDrequested && 
              (_vm.statusId === _vm.getApplicationStatuses.CertificateReady.id ||
              _vm.statusId === _vm.getApplicationStatuses.EDORegistration.id ||
              _vm.statusId === _vm.getApplicationStatuses.WaitingForOriginals.id ||
              _vm.statusId === _vm.getApplicationStatuses.OriginalsIncorrect.id ||
              _vm.statusId === _vm.getApplicationStatuses.Closed.id))?_c('tr',{staticClass:"upd-switch"},[_c('td',{staticClass:"upd-switch-item"},[_c('InputSwitch',{attrs:{"checked":_vm.isSendUPDtoEDM,"disabled":false},on:{"change":function (checked) {
                    _vm.toggleSendUPDtoEDM(checked);
                  }}})],1),_c('td',[_c('span',[_vm._v(" ")])]),_c('td',[_vm._v("Отправить УПД в ЭДО")])]):_vm._e(),_c('br'),(_vm.uploadFormControls.certificateSigned.fileId || _vm.uploadFormControls.printingFormSignature.fileId)?_c('tr',[_c('td',[_c('Button',{staticClass:"special-button",on:{"click":function($event){return _vm.handleGetCertificate()}}},[_vm._v(" "+_vm._s(!_vm.hideUtd && !_vm.isUPDrequested && (_vm.statusId === _vm.getApplicationStatuses.CertificateReady.id || _vm.statusId === _vm.getApplicationStatuses.EDORegistration.id || _vm.statusId === _vm.getApplicationStatuses.WaitingForOriginals.id || _vm.statusId === _vm.getApplicationStatuses.OriginalsIncorrect.id || _vm.statusId === _vm.getApplicationStatuses.Closed.id) ? "Получить сертификат и УПД" : "Получить сертификат")+" ")])],1),_c('td',[_c('Button',{staticClass:"special-button",on:{"click":function($event){return _vm.installCertificate(_vm.certificate[0])}}},[_vm._v(" Установить сертификат ")])],1)]):_c('tr',[_c('td',[_c('Button',{staticClass:"special-button",on:{"click":function($event){return _vm.$emit('openSignModal')}}},[_vm._v(" Подписать печатную форму ")])],1)]),_c('br'),_c('p',[_vm._v(" Также можно скачать: "),_c('br'),_c('button',{staticClass:"link",on:{"click":function($event){return _vm.downloadHandler(_vm.certificate[1])}}},[_vm._v("печатную форму сертификата")])]),_vm._l((_vm.getLicenses),function(license,index){return _c('div',{key:index},[_c('button',{staticClass:"link",on:{"click":function($event){return _vm.downloadHandler(license)}}},[_vm._v(_vm._s(license.licenseName))]),_c('br')])}),_c('p'),_c('br'),_c('p',[(!_vm.hideUtd)?[(_vm.isUPDrequested)?[(_vm.statusId === _vm.getApplicationStatuses.CertificateReady.id
                    || _vm.statusId === _vm.getApplicationStatuses.EDORegistration.id
                    || _vm.statusId === _vm.getApplicationStatuses.WaitingForOriginals.id
                    || _vm.statusId === _vm.getApplicationStatuses.OriginalsIncorrect.id
                    || _vm.statusId === _vm.getApplicationStatuses.Closed.id)?_c('ProposalFormDocument',{staticClass:"tab-4 complete",attrs:{"title":"Запрос УПД сформирован"}},[_c('br'),_c('span',[_vm._v("Статус: "+_vm._s(_vm.updStatus))]),(_vm.updStatusId === _vm.getUPDRequestStatuses.UPDSuccess)?_c('ItemDocument',{attrs:{"type":"doc","title":"УПД","descr":"универсальный передаточный документ","isDownloadable":true},on:{"click":function($event){return _vm.downloadHandler(_vm.updocument)}}}):_vm._e()],1):_vm._e()]:_vm._e()]:_vm._e()],2),_c('IconBase',{attrs:{"iconName":"cert","width":"197","height":"262","viewBox":"0 0 197 262"}},[_c('Cert')],1)]:[_c('p',[_vm._v(" Запрос отправлен. Документы отправлены на проверку. Сертификат ЭП в процессе выпуска. Готовый сертификат будет доступен здесь же. ")])]],2):(_vm.statusId === _vm.getApplicationStatuses.Rejection.id)?_c('ProposalFormDocument',{staticClass:"tab-4 complete",attrs:{"title":"В выпуске сертификата ЭП отказано"}},[_c('p',[_vm._v(" Проверьте комментарий к заявке. Он может содержать информацию о причинах отказа. ")]),_c('IconBase',{attrs:{"iconName":"cert","width":"197","height":"262","viewBox":"0 0 197 262"}},[_c('NotCert')],1)],1):_vm._e(),_c('ModalWithField',{attrs:{"name":"modal-edo-type","width":"400px","height":"364px","title":"Выберите систему ЭДО","description":"Внимание! В случае отмены отправка УПД в ЭДО будет выключена.","field":"select","label":"Отправить УПД в","defaultValue":0,"options":_vm.edmTypes},on:{"onClickOk":_vm.setEdmTypeId,"onClickCancel":function () { return _vm.toggleSendUPDtoEDM(false); }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }